import { createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "src/api/utils/enhancedFetch";
import { refreshSessionToken as refreshSessionTokenApi } from "src/features/signin/exports/api";
import { fetchSessionDetails } from "src/state/actionCreators/sessionDetails";
import {
  checkInVisitor,
  markVisitorDataStale,
} from "state/actionCreators/visitor";
import { RootState } from "state/delegate";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import {
  refreshSessionTokenSelectors,
  visitorSelectors,
} from "state/selectors";
import { RefreshSessionTokenState } from "state/tree/refreshSessionToken";

export const refreshSessionToken = createAsyncThunk<
  Data<RefreshSessionTokenState>,
  FetcherMetaV2 | void,
  { rejectValue: string; state: RootState }
>(
  "lwc/sessionDetails/refreshSessionToken",
  async (_, api) => {
    try {
      const { isVisitor } = visitorSelectors.data(api.getState());

      if (isVisitor) {
        api.dispatch(markVisitorDataStale());
        api.dispatch(checkInVisitor());

        return;
      }

      await refreshSessionTokenApi();
      api.dispatch(fetchSessionDetails());
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const meta = refreshSessionTokenSelectors.meta(state);

      return !meta.loading;
    },
  }
);
