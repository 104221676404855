export enum AxonMethods {
  INIT = "init",
  TRACK = "track",
}

export enum AxonEventNames {
  COMPLETED_LOGIN = "login",
  COMPLETED_REGISTRATION = "sign_up",
  PAGE_VIEW = "page_view",
  PURCHASE = "PURCHASE",
}

export enum AxonEventFields {
  CURRENCY = "currency",
  TRANSACTION_ID = "transaction_id",
  USER_ID = "user_id",
  VALUE = "value",
}
