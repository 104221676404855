import { MARKETING_PLATFORM, UtmParams } from "@analytics/enums";
import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { Nullable } from "src/types/common";

type UtmParamsPayload = { [key in UtmParams]?: Nullable<string> };

type PlatformValue = {
  clickId?: Nullable<string>;
} & UtmParamsPayload;

type MarketingPlatformPayload = { [key in MARKETING_PLATFORM]?: PlatformValue };

interface SendVisitMarketingBody {
  deviceId: string;
  platformPayload: MarketingPlatformPayload;
}

export interface GetUserHashedInfoResponse {
  email: Nullable<string>;
  hashedEmail: Nullable<string>;
  hashedIpAddress: Nullable<string>;
  hashedPhoneNumber: Nullable<string>;
  ipAddress: string;
  phoneNumber: Nullable<string>;
}

interface SendVisitMarketingParams {
  body: SendVisitMarketingBody;
  isVisitorPath: boolean;
}

interface SendEmailConsentParams {
  consentCampaignName?: string;
  email: string;
}

export const sendVisitMarketing = ({
  body,
  isVisitorPath,
}: SendVisitMarketingParams) =>
  fetch(
    `${getProxycadorHost()}${isVisitorPath ? "/visitors" : ""}/marketing-user-tracker/api/public/v1/visits`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(body),
    }
  );

export const getUserHashedInfo = (): Promise<GetUserHashedInfoResponse> =>
  fetch(
    `${getProxycadorHost()}/marketing-user-tracker/api/public/v1/user-info`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }
  ).then((resp) => resp.json());

export const sendEmailConsent = (body: SendEmailConsentParams) =>
  fetch(`${getProxycadorHost()}/email-marketing/api/public/v1/consents/email`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify(body),
  });
