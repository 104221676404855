import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ReadConversationParams } from "src/api/chat";
import {
  deleteAllMessageRequests as deleteAllMessageRequestsApi,
  deleteConversation,
  getRequestMessages,
  readConversationMessageRequest,
} from "src/features/chat/messageRequest/api/messageRequestApi";
import { messageRequestSelectors } from "src/features/chat/messageRequest/imports/state";
import {
  Nullable,
  RootState,
} from "src/features/chat/messageRequest/imports/types";
import { isApiError } from "src/features/chat/messageRequest/imports/utils";
import {
  DeleteMessageRequestsParams,
  DeleteMessageRequestsResponse,
  GetConversationMessageRequestParams,
  GetMessageRequestsParams,
  GetMessageRequestsResponse,
  ReadMessageRequestConversationResponse,
} from "src/features/chat/messageRequest/types";

export const setCurrentConversationId = createAction<Nullable<string>>(
  "lwc/messageRequests/setCurrentConversationId"
);

export const removeRequestsConversation = createAction<string>(
  "lwc/messageRequests/removeMessageRequestConversation"
);

export const fetchMessagesRequest = createAsyncThunk<
  GetMessageRequestsResponse,
  GetMessageRequestsParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/messageRequests/fetchMessagesRequest",
  async ({ resetOnError, isRefreshing, ...requestParams }, api) => {
    try {
      return await getRequestMessages(requestParams);
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const fetchConversationMessageRequest = createAsyncThunk<
  { conversationId: string } & GetConversationMessageRequestParams,
  { conversationId: string } & GetConversationMessageRequestParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/messageRequests/fetchConversationMessageRequest",
  async (payload, api) => {
    try {
      // TODO: remove reducer re-assign, make common conversations list
      // for all chat types (chat, message request, group chat)
      return payload;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const readMessageRequest = createAsyncThunk<
  ReadMessageRequestConversationResponse,
  ReadConversationParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/messageRequests/readMessages",
  async (params, api) => {
    try {
      return await readConversationMessageRequest(params);
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const conversation = messageRequestSelectors.getMessagesByConversationId(
        state,
        args.conversation_id
      );

      return !conversation?.isLoading;
    },
  }
);

export const removeMessagesRequest = createAsyncThunk<
  DeleteMessageRequestsResponse,
  DeleteMessageRequestsParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/messageRequests/removeMessagesRequest",
  async ({ conversationId }, api) => {
    try {
      return await deleteConversation(conversationId);
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const deleteAllMessageRequests = createAsyncThunk<
  DeleteMessageRequestsResponse,
  void,
  { rejectValue: string; state: RootState }
>("lwc/messageRequests/removeAllMessageRequests", async (_, api) => {
  try {
    return deleteAllMessageRequestsApi();
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
