import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { ReadConversationParams, ReadConversationResponse } from "src/api/chat";
import {
  DeleteMessageRequestsResponse,
  GetMessageRequestParams,
  GetMessageRequestResponse,
  GetMessageRequestsParams,
  GetMessageRequestsResponse,
  ParamConversationState,
} from "src/features/chat/messageRequest/types";

export const CHAT_CONVERSATIONS_LIST_DEFAULT_MESSAGES_LIMIT = 1;
export const CHAT_CONVERSATIONS_PAGE_SIZE = 20;

export const getRequestMessages = ({
  direction,
  last_update_request_timestamp,
  limit_conversations = CHAT_CONVERSATIONS_PAGE_SIZE,
  limit_messages_per_conversation = CHAT_CONVERSATIONS_LIST_DEFAULT_MESSAGES_LIMIT,
  ...rest
}: GetMessageRequestsParams): Promise<GetMessageRequestsResponse> =>
  fetch(`${getProxycadorHost()}/tc2/v2/messages/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      direction,
      last_update_request_timestamp,
      limit_conversations,
      limit_messages_per_conversation,
      state: ParamConversationState.CHAT_REQUEST,
      ...rest,
    }),
  }).then((resp) => resp.json());

export const getMessageRequest = (
  conversationId: string,
  params: GetMessageRequestParams
): Promise<GetMessageRequestResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/chats/v1/messages/get?conversationId=${conversationId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export const readConversationMessageRequest = (
  params: ReadConversationParams
): Promise<ReadConversationResponse> =>
  fetch(`${getProxycadorHost()}/tc2/v2/messages/read`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(params),
  }).then((resp) => resp.json());

export const deleteConversation = (
  conversationId: string
): Promise<GetMessageRequestsResponse> =>
  fetch(`${getProxycadorHost()}/tc2/v2/conversations/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      peer_ids: [conversationId],
    }),
  }).then((resp) => resp.json());

export const deleteAllMessageRequests =
  (): Promise<DeleteMessageRequestsResponse> =>
    fetch(`${getProxycadorHost()}/tc2/v2/conversation-request/delete-all`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((resp) => resp.json());
