import {
  type PayloadAction,
  SerializedError,
  createSlice,
} from "@reduxjs/toolkit";
import {
  DEFAULT_PAGE_SIZE,
  TOP_USERS_NUM_MOBILE,
} from "src/features/referralProgram/common/constants";
import {
  ReferralType,
  ReferredUsersOrder,
  ReferredUsersSortBy,
  UserType,
} from "src/features/referralProgram/common/enums";
import {
  QueryParams,
  ReferredUser,
  ReferredUsersMetaData,
} from "src/features/referralProgram/common/types";
import { loadReferralUsersList } from "src/features/referralProgram/state/asyncActions";
import { Nullable } from "src/types/common";
import { ApplicationThunk } from "state/types";

export interface ReferralProgramState {
  currency: "diamonds" | "usd";
  error: Nullable<SerializedError>;
  loading: boolean;
  meta: ReferredUsersMetaData;
  queryParams: QueryParams;
  referredUsers: Array<ReferredUser>;
  topReferredUsers: Array<ReferredUser>;
}

const initialState: ReferralProgramState = {
  referredUsers: [],
  topReferredUsers: [],
  meta: {
    pageCount: 1,
    totalEarnedPoints: 0,
    totalNewUsers: 0,
    totalPoints: 0,
    totalRedeemPoints: 0,
    totalUsers: 0,
  },
  loading: false,
  error: null,
  queryParams: {
    referralType: ReferralType.CREATORS,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    order: ReferredUsersOrder.DESC,
    sortBy: ReferredUsersSortBy.EARNED_POINTS,
    userType: UserType.ALL,
    username: "",
  },
  currency: "diamonds",
};

const slice = createSlice({
  name: "referralProgram",
  initialState,
  reducers: {
    setQueryParams(state, action: PayloadAction<Partial<QueryParams>>) {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadReferralUsersList.fulfilled, (state, action) => {
      state.loading = false;
      state.referredUsers = action.payload.data;
      state.topReferredUsers = action.payload.data.slice(
        0,
        TOP_USERS_NUM_MOBILE
      );
      state.meta = action.payload.meta;
    });
    builder.addCase(loadReferralUsersList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loadReferralUsersList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { setQueryParams } = slice.actions;

export const referralProgramReducer = slice.reducer;

export const changeQueryParams =
  (queryParams: Partial<QueryParams>): ApplicationThunk =>
  (dispatch) => {
    dispatch(setQueryParams(queryParams));
    dispatch(loadReferralUsersList());
  };
