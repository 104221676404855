import { enhancedFetch } from "src/features/signin/imports/api";
import { ServerErrorActionType } from "src/features/signin/imports/enums";
import { getProxycadorHost } from "src/features/signin/imports/environment";
import { isStandalone } from "src/features/signin/imports/utils";

export const fetchAllowedCredentialTypes = () =>
  enhancedFetch(
    `${getProxycadorHost()}/proxycador/api/login/v1/allowedCredentialTypes`
  ).then((resp) => resp.json());

export const registerGuest = (body) =>
  enhancedFetch(`${getProxycadorHost()}/proxycador/api/registerGuest/v1`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => resp.json());

export const upgradeGuest = (body) =>
  enhancedFetch(
    `${getProxycadorHost()}/proxycador/api/upgradeGuest/v1?standalone=${isStandalone}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(body),
    }
  ).then((resp) => resp.json());

export const getFacebookProfile = (token) =>
  enhancedFetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/facebookProfileByToken?token=${token}`
  ).then((resp) => resp.json());

export const fetchSessionDetails = () =>
  enhancedFetch(
    `${getProxycadorHost()}/session-service/v1/secure/session-details`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    }
  ).then((resp) => resp.json());

export const refreshSessionToken = () =>
  enhancedFetch(
    `${getProxycadorHost()}/proxycador/api/session/refresh`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    },
    { type: ServerErrorActionType.LOGOUT }
  );

export const registerVisitor = ({ body, provider }) =>
  enhancedFetch(
    `${getProxycadorHost()}/proxycador/api/visitors/register/v1/${provider}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(body),
    }
  ).then((resp) => resp.json());
