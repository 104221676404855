import type { ReferralProgramState } from "src/features/referralProgram/state/referralProgramSlice";

export const referralProgramSelectors = {
  isLoading: (state: ReferralProgramState) => state.loading,
  getReferredUsersList: (state: ReferralProgramState) => state.referredUsers,
  getTopReferredUsersList: (state: ReferralProgramState) =>
    state.topReferredUsers,
  getReferredUsersMeta: (state: ReferralProgramState) => state.meta,
  getCurrentPage: (state: ReferralProgramState) => state.queryParams.page,
  getPageSize: (state: ReferralProgramState) => state.queryParams.pageSize,
  getCurrency: (state: ReferralProgramState) => state.currency,
  getReferralType: (state: ReferralProgramState) =>
    state.queryParams.referralType,
  getUserType: (state: ReferralProgramState) => state.queryParams.userType,
  getUsername: (state: ReferralProgramState) => state.queryParams.username,
};
