export enum DeviceType {
  ANDROID = "android",
  DESKTOP = "desktop",
  IOS = "ios",
  IPAD = "ipad",
  MOBILE_OTHER = "mobile_other",
  UNKNOWN = "unknown",
}

export enum OSType {
  MAC_OS = "macOS",
  WINDOWS = "windows",
}

export enum Currency {
  AED = "AED",
  CAD = "CAD",
  EGP = "EGP",
  EUR = "EUR",
  GBP = "GBP",
  INR = "INR",
  KWD = "KWD",
  OMR = "OMR",
  QAR = "QAR",
  RUB = "RUB",
  SAR = "SAR",
  TRY = "TRY",
  USD = "USD",
}

export type CurrencyPricePoint = {
  currency: Currency;
  value: number;
};

export enum LotBetTargets {
  BET = "bet",
  BUYOUT = "buyout",
  RAISE = "raise",
}

// see also sassPartials/breakpoint

export enum Breakpoints {
  DESKTOP = "desktop",
  MOBILE = "mobile",
  SMALL_MOBILE = "smallMobile",
  TABLET = "tablet",
}

export enum MediaBreakpoints {
  BREAKPOINT_MOBILE_ONLY = "(max-width: 576px)",
  BREAKPOINT_NEW_DESKTOP_ONLY = "(min-width: 1024px)",
  BREAKPOINT_NEW_MOBILE_ONLY = "(min-width: 360px) and (max-width: 639px)",
  BREAKPOINT_NEW_SMALL_MOBILE_ONLY = "(max-width: 359px)",
  BREAKPOINT_NEW_TABLET_ONLY = "(min-width: 640px) and (max-width: 1023px)",
  BREAKPOINT_TABLET = "(min-width: 768px)", // see also sassPartials/breakpoint
  BREAKPOINT_TABLET_ONLY = "(min-width: 577px) and (max-width: 1024px)",
}

/**
 * @see https://github.com/ant-media/Ant-Media-Server/wiki/WebRTC-JavaScript-SDK-Guide#webrtc-javascript-info-callbacks
 */

export enum WebRtcAdaptorStatus {
  CLOSED = "closed",
  CLOSING = "WEB_RTC_ADAPTOR_STATUS_CLOSING",
  INITIALIZED = "initialized",
  PUBLISH_FINISHED = "publish_finished",
  PUBLISH_FINISHING = "WEB_RTC_ADAPTOR_STATUS_PUBLISH_FINISHING",
  PUBLISH_STARTED = "publish_started",
  PUBLISH_STARTING = "WEB_RTC_ADAPTOR_STATUS_PUBLISH_STARTING",
  SCREEN_SHARE_STOPPED = "screen_share_stopped",
  SCREEN_SHARE_SUPPORTED = "browser_screen_share_supported",
}

/**
 * @see https://github.com/ant-media/Ant-Media-Server/wiki/WebRTC-JavaScript-SDK-Guide#webrtc-javascript-error-callbacks
 */

export enum WebRtcAdaptorError {
  // Other errors
  ABORT = "AbortError",

  AUDIO_ALREADY_ACTIVE = "AudioAlreadyActive",
  CONSTRAINT_NOT_SATISFIED = "ConstraintNotSatisfiedError",

  NO_ENCODER_SETTINGS = "no_encoder_settings",
  NO_STREAM_NAME_SPECIFIED = "noStreamNameSpecified",

  // You are not allowed to access camera and mic.
  NOT_ALLOWED = "NotAllowedError",
  NOT_ALLOWED_UNREGISTERED_STREAMS = "not_allowed_unregistered_streams",

  // Camera or Mic are not found or not allowed in your device
  NOT_FOUND = "NotFoundError",

  // Camera or Mic is being used by some other process that does not let read the devices
  NOT_READABLE = "NotReadableError",
  NOT_SET_LOCAL_DESCRIPTION = "notSetLocalDescription",

  NOT_SUPPORTED = "NotSupportedError",

  // There is no device found that fits your video and audio constraints. You may change video and audio constraints
  OVERCONSTRAINED = "OverconstrainedError",
  PERMISSION_DENIED = "PermissionDeniedError",
  PUBLISH_TIMEOUT = "publishTimeoutError",
  // Screen Share
  SCREEN_SHARE_PERMISSION_DENIED = "ScreenSharePermissionDenied",
  SECURITY = "SecurityError",
  STREAM_ID_IN_USE = "streamIdInUse",
  TRACK_START = "TrackStartError",
  // Video/Audio is required
  TYPE = "TypeError",
  UNAUTHORIZED_ACCESS = "unauthorized_access",
  // Last resort
  UNKNOWN = "WEB_RTC_ADAPTOR_ERROR_UNKNOWN",
  // Fatal Error: Browser cannot access camera and mic because of unsecure context. Please install SSL and access via https
  UNSECURE_CONTEXT = "UnsecureContext",

  VIDEO_ALREADY_ACTIVE = "VideoAlreadyActive",

  // Fatal Error: WebSocket not supported in this browser
  WEB_SOCKET_NOT_SUPPORTED = "WebSocketNotSupported",
}

export enum BroadcastStatus {
  BAD_PICTURE = "BROADCAST_STATUS_BAD_PICTURE",
  INIT_FAILED = "BROADCAST_STATUS_INIT_FAILED",
  INIT_STARTED = "BROADCAST_STATUS_INIT_STARTED",
  INIT_SUCCEEDED = "BROADCAST_STATUS_INIT_SUCCEEDED",
  INIT_USER_BAN = "BROADCAST_STATUS_INIT_USER_BAN",
  TERMINATE_FAILED = "BROADCAST_STATUS_TERMINATE_FAILED",
  TERMINATE_STARTED = "BROADCAST_STATUS_TERMINATE_STARTED",
  TERMINATE_SUCCEEDED = "BROADCAST_STATUS_TERMINATE_SUCCEEDED",
}

export enum BroadcastPictureUploadStatus {
  FAILED = "BROADCAST_PICTURE_STATUS_UPLOAD_FAILED",
  STARTED = "BROADCAST_PICTURE_STATUS_UPLOAD_STARTED",
  SUCCEEDED = "BROADCAST_PICTURE_STATUS_UPLOAD_SUCCEEDED",
}

export enum LandingPageDismissType {
  AMOUNT_PER_USER_EXCEEDED = "AMOUNT_PER_USER_EXCEEDED",
  DEFAULT = "DEFAULT",
  PAGE_TIMER_LIMIT = "PAGE_TIMER_LIMIT",
  PURCHASE_PERIOD_COOLDOWN = "PURCHASE_PERIOD_COOLDOWN",
  PURCHASE_WITH_AMOUNT_EXCEEDED = "PURCHASE_WITH_AMOUNT_EXCEEDED",
  PURCHASE_WITH_PERIOD_DONE = "PURCHASE_WITH_PERIOD_DONE",
}

export enum GiftsDrawerLayout {
  BOTTOM_SCREEN = "bottomScreen",
  MODAL = "modal",
  RIGHT_SIDE = "rightSide",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NONE = "NONE",
}

export enum AvatarSize {
  LARGE = "large",
  MEDIUM = "medium",
  MEDIUM_PLUS = "mediumPlus",
  SEMI_LARGE = "semiLarge",
  SEMI_MEDIUM = "semiMedium",
  SMALL = "small",
  SMALL_PLUS = "smallPlus",
  TINY = "tiny",
  XSMALL = "xsmall",
}

export enum CoinIconSize {
  SMALL = "small",
  SMALL_PLUS = "smallPlus",
}

export enum VipConfigState {
  PRIVATE = "PRIVATE",
  PURCHASABLE = "PURCHASABLE",
}

export enum VipName {
  BLACK_DIAMOND = "Black Diamond",
  BRONZE = "Bronze",
  DIAMOND = "Diamond",
  ELITE = "Elite",
  GOLD = "Gold",
  LEGEND = "Legend",
  MEMBER = "Member",
  NONE = "None",
  ROOKIE = "Rookie",
  ROYAL = "Royal",
  SILVER = "Silver",
}

// Make sure these variables match the values from sassPartials/vipClassNames file
export enum VipClassName {
  BLACK_DIAMOND = "vipBlackDiamond",
  BRONZE = "vipBronze",
  DIAMOND = "vipDiamond",
  ELITE = "vipElite",
  GOLD = "vipGold",
  LEGEND = "vipLegend",
  MEMBER = "vipMember",
  NONE = "vipNone",
  ROOKIE = "vipRookie",
  ROYAL = "vipRoyal",
  SILVER = "vipSilver",
}

export enum VipLabel {
  BLACK_DIAMOND = "black_diamond",
  BRONZE = "bronze",
  DIAMOND = "diamond",
  ELITE = "platinum",
  GOLD = "gold",
  LEGEND = "legend",
  MEMBER = "member",
  NONE = "none",
  ROOKIE = "rookie",
  ROYAL = "royal",
  SILVER = "silver",
}

export enum TimeFrame {
  DAILY = "lastday",
  LIFETIME = "lifetime",
  THISWEEK = "thisweek",
}

export enum ThemeType {
  DARK = "dark",
  LIGHT = "light",
}

export enum DataDogErrorName {
  ACCEPT_MULTI_BROADCAST_INVITE = "ACCEPT_MULTI_BROADCAST_INVITE",
  FAILED_TO_AUTO_LOGIN = "FAILED_TO_AUTO_LOGIN",
  LANDING_PAGE_REDIRECT = "LANDING_PAGE_REDIRECT",
  REJECT_MULTI_BROADCAST_INVITE = "REJECT_MULTI_BROADCAST_INVITE",
  SESSION_TOKEN_REFRESH = "SESSION_TOKEN_REFRESH",
  VALIDATE_SESSION_DETAILS = "VALIDATE_SESSION_DETAILS",
}

export enum FollowSource {
  AUTO_FOLLOW_GIFT = 17,
  CHAT = 4,
  FAVORITES = 8,
  LEADERBOARD = 2,
  NOTIFICATION_BELL = 3,
  STORIES = 6,
  STORIES_MINI_PROFILE = 7,
  STREAM_ENDED_VIEW = 9,
  STREAM_GIFTER_LIST = 5,
  STREAM_MINI_PROFILE = 1,
  STREAM_OVERLAY = 12,
  TCNN = 10,
  USER_PROFILE = 0,
  USER_SEARCH_RESULTS = 11,
}

export enum TCNNAction {
  BUY_COINS = "BUY_COINS",
  DEEPLINK = "DEEPLINK",
  FOLLOW = "FOLLOW",
  LINK = "LINK",
  MESSAGE = "MESSAGE",
  SEND_ANY_GIFT = "SEND_ANY_GIFT",
  SEND_GIFT_AND_MESSAGE = "SEND_GIFT_AND_MESSAGE",
  SEND_THIS_GIFT = "SEND_THIS_GIFT",
  SUBSCRIBE = "SUBSCRIBE",
}

export enum StreamSessionInitializationResult {
  EXPIRED = "expired",
  KICKED_OUT = "kicked_out",
  LOGIN_REQUIRED = "login_required",
  NOT_INITIALIZED = "not_initialized",
  PAYMENT_REQUIRED = "payment_required",
  SUCCESS = "success",
  TERMINATED = "terminated",
}

export enum MiniProfileEntranceSource {
  DIRECT_EXTERNAL_LINK = 10,
  FEED = 21,
  LEADERBOARD = 20,
  LEADERBOARD_LANDING_PAGE = 26,
  NFT_CARD = 17,
  OFFLINE_CHAT = 22,
  PROFILE_ICON_AT_TAB = 24,
  REPORT = 25,
  STORIES = 6,
  STREAM_CHAT = 0,
  STREAM_GAME = 5,
  STREAM_GIFT = 1,
  STREAM_PUBLISHER_AVATAR = 2,
  STREAM_SUBSCRIBE = 8,
  STREAM_SUBSCRIBE_FROM_HAPPY_MOMENTS = 9,
  STREAM_TOP_GIFTERS = 3,
  TOURNAMENT_LANDING_PAGE = 11,
  UNKNOWN = -1,
}

export enum StreamEvents {
  BONUS = "BONUS",
  CENSORED_MESSAGE = "MESSAGE_CENSORED",
  FAILED_TO_SEND = "FAILED_TO_SEND",
  FREE_PREMIUM_JOIN = "FREE_PREMIUM_JOIN",
  GAME_EVENTS = "GAME_EVENTS",
  GAME_INFO = "GAME_INFO",
  GIFT = "GIFT",
  JOIN = "JOIN",
  MESSAGE = "MESSAGE",
  MESSAGE_LIKE_EVENT = "MESSAGE_LIKE_EVENT",
  MULTI_BROADCAST = "MULTIBROADCAST",
  MULTI_BROADCAST_GIFT = "MULTI_BROADCAST_GIFT",
  PREMIUM_ENTRY = "PREMIUM_ENTRY",
  PROMOTION = "PROMOTION",
  SUBSCRIPTION = "SUBSCRIPTION",
  TICKET = "TICKET",
  WARNING = "WARNING",
}

export enum MiniProfileState {
  BECOME_A_FAN = "BECOME_A_FAN",
  REMOVE_VIEWER = "REMOVE_VIEWER",
  REPORT = "REPORT",
}

export enum FanLevel {
  EXCLUSIVE_FAN = 3,
  FAN = 2,
  SUPER_FAN = 1,
}

export enum BannerType {
  WEB_BANNER = "WEB_BANNER",
}

export enum ToastType {
  HEADLINE = "headline",
  INVITE = "invite",
  REGULAR = "regular",
  TIMER = "timer",
}

export enum LiveFeedType {
  CREATORS = "creators",
  LANDSCAPE = "landscape",
  NEARBY = "nearby",
  NEW = "new",
  POPULAR = "popular",
  RECOMMENDED = "recommended",
}

export enum LiveFeedDynamicTabType {
  BATTLE = "battle",
  GAMES = "games",
  LIVEPARTY = "liveparty",
  RISING_STARS = "rising_stars",
  TOURNAMENTS = "tournaments",
}

export type CombinedLiveFeedBannerType = LiveFeedDynamicTabType | LiveFeedType;

export enum PersonalFeedType {
  // HINT: It might be deprecated after updating all streams backend endpoints
  ALL = "all",
  FOLLOWING = "following",
  // Jira ticket: https://tango-me.atlassian.net/browse/WEB-4575
  OLD_FOLLOWING = "old_following",
  RECOMMENDED = "following_recommendations",
}

export type CombinedFeedType = LiveFeedType | PersonalFeedType;

export enum LoginPromotionType {
  CHAT_IN_STREAM = "CHAT_IN_STREAM",
  CHAT_TAB = "CHAT_TAB",
  DEFAULT = "DEFAULT",
  FEED_TAB = "FEED_TAB",
  FOLLOW = "FOLLOW",
  GIFT = "GIFT",
  SESSION_EXPIRED = "SESSION_EXPIRED",
  SUBSCRIBE = "SUBSCRIBE",
  TRANSITION_TO_PRIVATE = "TRANSITION_TO_PRIVATE",
}

export enum LoginResult {
  GUEST_UPGRADE_FAILED = "GUEST_UPGRADE_FAILED",
  LIMIT_REACHED = "LIMIT_REACHED",
  LOGGED_IN = "LOGGED_IN",
  LOGGED_IN_AS_GUEST = "LOGGED_IN_AS_GUEST",
  LOGGED_IN_LIMITED = "LOGGED_IN_LIMITED",
  METHOD_UNAVAILABLE = "METHOD_UNAVAILABLE",
  METHOD_UNAVAILABLE_FOR_REGISTERED_USER = "METHOD_UNAVAILABLE_FOR_REGISTERED_USER",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  VERIFICATION_REQUIRED = "VERIFICATION_REQUIRED",
  VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE = "VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE",
  WRONG_CODE = "WRONG_CODE",
}

export enum LoginProvider {
  APPLE = "APPLE_WEB",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  TANGO_DISPOSABLE_TOKEN = "TANGO_DISPOSABLE_TOKEN",
  TANGO_PHONE_NUMBER = "TANGO_PHONE_NUMBER",
}

export enum ModalType {
  ALERT = "ALERT",
  BECOME_A_FAN_MODAL = "BECOME_A_FAN_MODAL",
  BLOCK_LIST_MODAL = "BLOCK_LIST_MODAL",
  BLOCK_USER_MODAL = "BLOCK_USER_MODAL",
  BOTTOM_SHEET = "BOTTOM_SHEET",
  BUY_COINS = "BUY_COINS",
  CANNOT_SEND_GIFT = "CANNOT_SEND_GIFT",
  CAPTCHA_MODAL = "CAPTCHA_MODAL",
  CASHIER_MODAL = "CASHIER_MODAL",
  CHECKOUT = "CHECKOUT",
  CONFIRMATION_MODAL = "CONFIRMATION_MODAL",
  DELETE_ACCOUNT_MODAL = "DELETE_ACCOUNT_MODAL",
  DELETE_ALL_MESSAGE_REQUESTS = "DELETE_ALL_MESSAGE_REQUESTS",
  DELETE_CONVERSATION_MODAL = "DELETE_CONVERSATION_MODAL",
  DELETE_MESSAGE_REQUEST = "DELETE_MESSAGE_REQUEST",
  DELETE_STORY_CONFIRMATION_MODAL = "DELETE_STORY_CONFIRMATION_MODAL",
  DISCONNECT_INSTAGRAM_CONFIRMATION_MODAL = "DISCONNECT_INSTAGRAM_CONFIRMATION_MODAL",
  DOWNLOAD_OBS_PROFILE = "DOWNLOAD_OBS_PROFILE",
  EDIT_PROFILE = "EDIT_PROFILE",
  GIFT_FROM_BC_MODAL = "GIFT_FROM_BC_MODAL",
  GIFTS_DRAWER = "GIFTS_DRAWER",
  INFORMATION_MODAL = "PERMISSIONS_INFO",
  INSTAGRAM_ALLOW_ALL_PHOTOS_MODAL = "INSTAGRAM_ALLOW_ALL_PHOTOS",
  INSTAGRAM_CHOOSE_PHOTOS_MODAL = "INSTAGRAM_CHOOSE_PHOTOS_MODAL",
  INSTAGRAM_POSTS_MODAL = "INSTAGRAM_POSTS_MODAL",
  LANDING = "LANDING",
  LANDING_MODAL = "LANDING_MODAL",
  LOGIN = "LOGIN",
  LOGOUT_MODAL = "LOGOUT_MODAL",
  MINI_PROFILE_MODAL = "MINI_PROFILE_MODAL",
  NFT_BID_CARD_MODAL = "NFT_BID_CARD_MODAL",
  NFT_CARD_MODAL = "NFT_CARD_MODAL",
  NFT_DELETE_CARD_MODAL = "NFT_DELETE_CARD_MODAL",
  NFT_REMOVE_CARD_MODAL = "NFT_REMOVE_CARD_MODAL",
  PROFILE_EDIT_ERROR = "PROFILE_EDIT_ERROR",
  PROFILE_SETTINGS_DELETE_EMAIL = "PROFILE_SETTINGS_DELETE_EMAIL",
  PROFILE_SETTINGS_DELETE_PHONE_NUMBER = "PROFILE_SETTINGS_DELETE_PHONE_NUMBER",
  PROFILE_SETTINGS_EDIT_PHONE_NUMBER = "PROFILE_SETTINGS_EDIT_PHONE_NUMBER",
  PROFILE_SETTINGS_EMAIL = "PROFILE_SETTINGS_EMAIL",
  REDEEM_GIFT_CARD_MODAL = "REDEEM_GIFT_CARD_MODAL",
  REFERRAL_HOW_IT_WORKS_MODAL = "REFERRAL_HOW_IT_WORKS_MODAL",
  REFILL_MODAL = "REFILL_MODAL",
  REGISTRATION_DISABLED = "REGISTRATION_DISABLED",
  REPORT_PROFILE_MODAL = "REPORT_PROFILE_MODAL",
  SEND_MEDIA_TO_CHAT = "SEND_MEDIA_TO_CHAT",
  SOCIAL_NETWORK_ACCOUNTS = "SOCIAL_NETWORK_ACCOUNTS",
  SUBSCRIBE_MODAL = "SUBSCRIBE_MODAL",
  TCNN_MODAL = "TCNN_MODAL",
  UNBLOCK_USER_MODAL = "UNBLOCK_USER_MODAL",
  UNFOLLOW_MODAL = "UNFOLLOW_MODAL",
  UNSUBSCRIBE_STREAMER = "UNSUBSCRIBE_STREAMER",
  WELCOME_ONBOARDING_OFFER = "WELCOME_ONBOARDING_OFFER",
}

export enum ModalScreenName {
  ALERT = "alert",
  BECOME_A_FAN = "become_a_fan",
  BLOCK_LIST = "block_list",
  BOTTOM_SHEET = "bottom_sheet",
  CAPTCHA = "captcha",
  CASHIER = "cashier",
  CHECKOUT = "checkout",
  CONFIRM_BLOCK_USER = "confirm_block_user",
  CONFIRM_DELETE_ALL_MESSAGE_REQUESTS = "confirm_delete_all_message_requests",
  CONFIRM_DELETE_CONVERSATION = "confirm_delete_conversation",
  CONFIRM_DELETE_MESSAGE_REQUEST = "confirm_delete_message_request",
  CONFIRM_DELETE_STORY = "confirm_delete_story",
  CONFIRM_DISCONNECT_INSTAGRAM = "confirm_disconnect_instagram",
  CONFIRM_LOGOUT = "confirm_logout",
  CONFIRM_UNBLOCK_USER = "confirm_unblock_user",
  CONFIRM_UNFOLLOW = "confirm_unfollow",
  CONFIRMATION = "confirmation",
  DELETE_ACCOUNT_MODAL = "delete_account_modal",
  DOWNLOAD_OBS_PROFILE = "DOWNLOAD_OBS_PROFILE",
  GET_IN_APP = "get_the_app",
  GIFT_FROM_BC = "gift_from_bc",
  IMAGE_PICKER_PREVIEW = "image_picker.preview",
  INFO = "info",
  INSTAGRAM = "instagram",
  INSTAGRAM_ALLOW_ALL_PHOTOS = "instagram_allow_all_photos",
  INSTAGRAM_CHOSE_PHOTOS = "instagram_choose_photos",
  LANDING = "landing",
  LANDING_MODAL = "landing_modal",
  MINI_PROFILE = "mini_profile",
  NFT_AUCTION_CARD = "nft_auction_card",
  PROFILE_EDIT = "profile_edit",
  PROFILE_EDIT_ERROR = "profile_edit.error",
  PROFILE_SETTINGS_DELETE_EMAIL = "profile_settings_delete_email",
  PROFILE_SETTINGS_EMAIL = "profile_settings_email",
  REDEEM_GIFT_CARD_MODAL = "redeem_gift_card_modal",
  REFERRAL_HOW_IT_WORKS = "REFERRAL_HOW_IT_WORKS",
  REFILL = "refill",
  REFILL_V2 = "refill_v2",
  REGISTRATION_DISABLED = "registration.disabled",
  REGISTRATION_SIGN_IN = "registration.sign_in",
  REPORT_PROFILE = "report.profile",
  SEND_GIFT = "send_gift",
  SEND_GIFT_ERROR = "send_gift.error",
  SETTINGS = "settings",
  SETTINGS_CONNECTED_ACCOUNTS = "settings.connected_accounts",
  SETTINGS_REMOVE_NFT_AUCTION_CARD = "settings.remove_nft_auction_card",
  SUBSCRIBE = "settings.subscribe_modal",
  TCNN = "tcnn",
  UNSUBSCRIBE_STREAMER = "unsubscribe_streamer",
  WELCOME_ONBOARDING_OFFER = "welcome_offer_newcomer",
}

export enum LoginModalTitleType {
  BROADCAST = "broadcast",
  DO_MORE_WITH_TANGO = "do_more_with_tango",
  LOGIN_OR_SIGNUP = "login_or_signup",
  REGULAR = "regular",
  REPORT = "report",
  SEND_MESSAGES = "send_messages",
}

export enum BottomScreenType {
  BOTTOM_NFT_REMOVE_CARD_MODAL = "BOTTOM_NFT_REMOVE_CARD_MODAL",
  BOTTOM_OFFERS = "BOTTOM_OFFERS",
  BOTTOM_SHEET = "BOTTOM_SHEET",
  CASHIER_V2_DRAWER_BOTTOM_SCREEN = "CASHIER_V2_DRAWER_BOTTOM_SCREEN",
  CHECKOUT_BOTTOM_SCREEN = "CHECKOUT_BOTTOM_SCREEN",
  CONFIRMATION_BOTTOM_SCREEN = "CONFIRMATION_BOTTOM_SCREEN",
  CONFIRMATION_BOTTOM_SCREEN_SHADER = "CONFIRMATION_BOTTOM_SCREEN_SHADER",
  CURRENCY_BOTTOM_SCREEN = "CURRENCY_BOTTOM_SCREEN",
  DELETE_ACCOUNT_MODAL = "DELETE_ACCOUNT_MODAL",
  DELETE_PHONE_NUMBER_BOTTOM_SCREEN = "DELETE_PHONE_NUMBER_BOTTOM_SCREEN",
  EDIT_PHONE_NUMBER_BOTTOM_SCREEN = "EDIT_PHONE_NUMBER_BOTTOM_SCREEN",
  GIFT_FROM_BC_BOTTOM_SCREEN = "GIFT_FROM_BC_BOTTOM_SCREEN",
  GIFTS_DRAWER_BOTTOM_SCREEN = "GIFTS_DRAWER_BOTTOM_SCREEN",
  INSTAGRAM_ALLOW_ALL_PHOTOS_BOTTOM_SCREEN = "INSTAGRAM_ALLOW_ALL_PHOTOS_BOTTOM_SCREEN",
  INSTAGRAM_DISCONNECT_CONFIRM_BOTTOM_SCREEN = "INSTAGRAM_DISCONNECT_CONFIRM_BOTTOM_SCREEN",
  INSTAGRAM_POSTS_BOTTOM_SCREEN = "INSTAGRAM_POSTS_BOTTOM_SCREEN",
  INSTALL_TANGO_APP_BOTTOM_SCREEN = "INSTALL_TANGO_APP_BOTTOM_SCREEN",
  LANDING_BOTTOM_SCREEN = "LANDING_BOTTOM_SCREEN",
  LANDING_PAGE_BOTTOM_SCREEN = "LANDING_PAGE_BOTTOM_SCREEN",
  MINI_PROFILE_BOTTOM_SCREEN_SHADER = "MINI_PROFILE_BOTTOM_SCREEN_SHADER",
  NFT_BID_CARD_BOTTOM_SCREEN = "NFT_BID_CARD_BOTTOM_SCREEN",
  NFT_CARD_BOTTOM_SCREEN = "NFT_CARD_BOTTOM_SCREEN",
  NFT_DELETE_CARD_BOTTOM_SCREEN = "NFT_DELETE_CARD_BOTTOM_SCREEN",
  PROFILE_SETTINGS_DELETE_EMAIL = "PROFILE_SETTINGS_DELETE_EMAIL",
  PROFILE_SETTINGS_EMAIL = "PROFILE_SETTINGS_EMAIL",
  PROFILE_SETTINGS_OPTIONS_BOTTOM_SCREEN = "PROFILE_SETTINGS_OPTIONS_BOTTOM_SCREEN",
  REFILL_DRAWER_BOTTOM_SCREEN = "REFILL_DRAWER_BOTTOM_SCREEN",
  REFILL_V2_DRAWER_BOTTOM_SCREEN = "REFILL_V2_DRAWER_BOTTOM_SCREEN",
  REPORT_BOTTOM_SCREEN = "REPORT_BOTTOM_SCREEN",
  REPORT_BOTTOM_SCREEN_SHADER = "REPORT_BOTTOM_SCREEN_SHADER",
  SELECT_COUNTRY_BOTTOM_SCREEN = "SELECT_COUNTRY_BOTTOM_SCREEN",
  SEND_MEDIA_TO_CHAT = "SEND_MEDIA_TO_CHAT",
  SIGN_UP_BOTTOM_SCREEN_DARK = "SIGN_UP_BOTTOM_SCREEN_DARK",
  SIGN_UP_BOTTOM_SCREEN_FULL_HEIGHT = "SIGN_UP_BOTTOM_SCREEN_FULL_HEIGHT",
  SIGN_UP_BOTTOM_SCREEN_WHITE = "SIGN_UP_BOTTOM_SCREEN_WHITE",
  SOCIAL_GAMES_BOTTOM_SCREEN = "SOCIAL_GAMES_BOTTOM_SCREEN",
  SUBSCRIBE_BOTTOM_SCREEN = "SUBSCRIBE_BOTTOM_SCREEN",
  SUBSCRIBE_BOTTOM_SCREEN_SHADER = "SUBSCRIBE_BOTTOM_SCREEN_SHADER",
  TOP_GIFTERS_IN_STREAM = "TOP_GIFTERS_IN_STREAM",
  USER_PROFILE_SCREEN = "USER_PROFILE_SCREEN",
  USER_PROFILE_SCREEN_SHADER = "USER_PROFILE_SCREEN_SHADER",
  USER_UNSUBSCRIBE_SCREEN = "USER_UNSUBSCRIBE_SCREEN",
}

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  ANIMATION_TYPE_MOMENT_ONLY = "MOMENT_ONLY",
  CANCELLED = "CANCELLED",
  FREE_TRIAL = "FREE_TRIAL",
  GRACE_PERIOD = "GRACE_PERIOD",
  MY_SUBSCRIBERS_LAST_VISIT = "mySubscribersLastVisitDate",
  PENDING = "PENDING",
  RENEWING = "RENEWING",
}

export enum ProfileType {
  MINI_PROFILE = "mini-profile",
  PROFILE = "profile",
}

export enum PostsFeedType {
  MINE = "mine",
  OTHERS = "others",
  OTHERS_SUB_ONLY = "othersSubOnly",
  PHOTOS_SUB_ONLY = "photosSubOnly",
  TIMELINE = "timeline",
  TIMELINE_SUB_ONLY = "timelineSubOnly",
}

export enum FeedType {
  FEED = "feed",
  SUBSCRIBERS_ONLY = "subscribersOnly",
}

export enum FeedLoadDirection {
  NEWER = "newer",
  OLDER = "older",
  REFRESH = "refresh",
}

export enum SendStreamMessageResponseCode {
  CENSORED = 5,
  FAILED_TO_SEND = 7,
  OK = 0,
  PAYWALL = 9,
  QUOTA_EXCEEDED = 13,
}

export enum StreamSourceChatType {
  GROUP = 1,
  LIVE_FAMILY = 2,
  SINGLE = 0,
}

export enum NotificationType {
  SS_CHAT_MESSAGE = "ss_chat_message",
  STREAM_BEGAN = "stream_began",
  STREAM_REDIRECT = "stream_redirect",
  TC_MESSAGE = "tc_message",
}

export enum StreamVideoUrl {
  NO_URL = "NO_URL",
}

export enum PersonalOfferType {
  CASHIER = "CASHIER",
  LANDING_PAGE = "LANDING_PAGE",
  LOYALTY_OFFERS = "LOYALTY_OFFERS",
  MINTROUTE = "MINTROUTE",
  REFILL = "REFILL",
}

export enum AcmeServiceName {
  FINANCE = "finance",
  GIFT = "gift",
  PERSONAL_OFFERS = "personal_offers",
  SUBSCRIPTION_NOTIFICATION_SERVICE = "subscriptionNotificationService",
}

export enum AcmeServiceIdentifier {
  PERSONAL_OFFERS_ACME = "personal_offer_acme",
  SYNC_CREDIT = "syncCredit",
  TRANSACTION_STATUS_MESSAGE_ACME = "transaction_status_message",
}

export enum Platforms {
  WEB = "web",
}

export enum BrowserType {
  CHROME = "Chrome",
  SAFARI = "Safari",
}

export enum GiftOriginType {
  GIFT_ORIGIN_AI_GIFT = 2,
  GIFT_ORIGIN_PREMIUM_MESSAGE = 1,
}

export enum ChatErrorType {
  SEND_MESSAGE_ERROR = "sendMessageError",
  TRANSLATION_ERROR = "translationError",
}

export enum DisplayAreas {
  IN_STREAM = "IN_STREAM",
  LIVE_GRID = "LIVE_GRID",
  OFFLINE_CHAT = "OFFLINE_CHAT",
}

export enum ServerErrorActionType {
  LOGOUT = "logout",
}

export enum ConfigurationStateKey {
  IS_SERVER_CONFIG_LOADED = "isServerConfigLoaded",
  OVERRIDE_CONFIG = "overrideConfig",
  SERVER_CONFIG = "serverConfig",
}

export enum SignUpEntryPoint {
  REGISTRATION = "registration",
  REGPAGE_1 = "regpage1",
}

export enum DocumentVisibilityState {
  HIDDEN = "hidden",
  VISIBLE = "visible",
}

export enum componentsVariants {
  OUTLINED = "outlined",
  SHADER = "shader",
}

export enum keyboardEvents {
  ARROW_DOWN = "ArrowDown",
  ARROW_UP = "ArrowUp",
  ENTER = "Enter",
  ESCAPE = "Escape",
  SPACE = "Space",
}

export enum TooltipPosition {
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
}

export enum PopoverPosition {
  BOTTOM = "bottom",
  BOTTOM_LEFT = "bottomLeft",
  BOTTOM_RIGHT = "bottomRight",
  LEFT = "left",
  LEFT_BOTTOM = "leftBottom",
  LEFT_TOP = "leftTop",
  RIGHT = "right",
  RIGHT_BOTTOM = "rightBottom",
  RIGHT_TOP = "rightTop",
  TOP = "top",
  TOP_LEFT = "topLeft",
  TOP_RIGHT = "topRight",
}

export enum ScreenName {
  LP_REGULAR = "lpRegular",
}

export enum StreamSessionInitializationCode {
  EXISTING_LIVE_STREAM = "EXISTING_LIVE_STREAM",
  SUCCESS = "SUCCESS",
}

export enum BroadcastErrorType {
  FAILED_TO_START = "FAILED_TO_START",
  NOT_READABLE = "NOT_READABLE",
  PERMISSION_DENIED = "PERMISSION_DENIED",
  UNKNOWN = "UNKNOWN",
}

export enum BroadcastPillKind {
  DANGER = "danger",
  PREMIUM = "premium",
  PRIMARY = "primary",
}

export enum BroadcastSource {
  BROADCASTING = "broadcasting",
}
