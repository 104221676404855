import React, { memo } from "react";
import FormattedMessageTitle from "ui/common/documentTitle/FormattedMessageTitle";
import { messages as legalMessages } from "./common/messages";
import { PostDate } from "./components";
import {
  LinkToBroadcasterAgreement,
  LinkToCommunityGuidelines,
  LinkToCopyright,
  LinkToPrivacyPolicy,
  LinkToTangoWebsite,
  MailToLegal,
  MailToSupport,
} from "./components/links";
import { Headline, ListItem, Paragraph } from "./components/typography";
import styles from "./common/LegalArticle.scss";

/* eslint-disable react/jsx-no-literals, react/no-unescaped-entities */
const TermsOfUse: React.FC = () => (
  <>
    <FormattedMessageTitle {...legalMessages.termsOfUse} />

    <article className={styles.article}>
      <PostDate>Last Modified: 08.08.2024</PostDate>

      <Headline level={1}>Terms of Use</Headline>

      <Paragraph>
        <b>IMPORTANT NOTICE:</b> THESE TERMS ARE SUBJECT TO A BINDING
        ARBITRATION PROVISION AND WAIVER OF CLASS ACTION RIGHTS, AS DETAILED IN
        SECTION 22 BELOW. YOU AGREE THAT ANY CLAIM OR DISPUTE AT LAW OR EQUITY
        THAT HAS ARISEN OR MAY ARISE PURSUANT TO THESE TERMS OR THE ACCESS OR
        USE OF THE SERVICES, AS DEFINED BELOW, WILL BE RESOLVED IN ACCORDANCE
        WITH SECTION 22 BELOW. PLEASE READ THAT SECTION CAREFULLY. IT AFFECTS
        YOUR LEGAL RIGHTS AND OBLIGATIONS.
      </Paragraph>
      <Headline level={2}>Introduction</Headline>
      <Paragraph>
        Please carefully read the following provisions of this Terms of Use{" "}
        <b>("Terms")</b>. These Terms constitute a binding legal agreement
        between you (<b>"you"</b>, <b>"your"</b> or <b>"user"</b>) and TangoMe,
        Inc. or TangoMe Cyprus, as applicable (collectively, <b>"Tango"</b>,{" "}
        <b>"we"</b>, <b>"our"</b>, or <b>"us"</b>), governing your access and
        use of all features, content, and other services provided by Tango,
        including without limitation, our website available from{" "}
        <LinkToTangoWebsite /> and all subdomains (<b>"Website"</b>) and
        applications (<b>"App(s)"</b>; collectively with the Website,{" "}
        <b>"Services"</b>). All references to <b>"you"</b> or
        <b>"your"</b> as applicable, mean the person who accesses, uses, or
        participates in the Services. If you are located in the EU, the
        contracting entity is TangoMe Cyprus; for all other users, the
        contracting entity is TangoMe Inc.
      </Paragraph>
      <Paragraph>
        By registering an account, using any of our features and Services, or
        accessing the Services in any manner, you acknowledge and affirm that
        you have read, understood, accept, and agree to be bound by these Terms
        (including any additional guidelines and future modifications), our{" "}
        <LinkToPrivacyPolicy /> (<b>"Privacy Policy"</b>), <LinkToCopyright /> (
        <b>"Copyright Policy"</b>) and <LinkToCommunityGuidelines /> (
        <b>"Community Guidelines"</b>). If you are a broadcaster, you are also
        bound by our <LinkToBroadcasterAgreement /> (<b>"Broadcaster Terms"</b>
        ). Each of these documents is incorporated herein by reference.
      </Paragraph>
      <Paragraph>
        If you do not agree to these Terms, along with any additional policies,
        guidelines, or Broadcaster Terms, you may not register an account or
        otherwise use or access the Services.
      </Paragraph>
      <Headline level={2}>Eligibility</Headline>
      <Paragraph>
        The Services are intended solely for users who are at least eighteen
        (18) years of age, or older, and can form a legally binding contract
        under applicable law (collectively, <b>"Age of Consent"</b>). Any
        registration, use or access to the Services by anyone under the Age of
        Consent is unauthorized, unlicensed, and in violation of the Terms.
      </Paragraph>
      <Paragraph>
        By accessing or otherwise using the Services, you represent and warrant
        that you: (a) are a natural person over the Age of Consent, who is
        personally assigned to the email address and other information submitted
        in relation to your account; (b) agree to abide by these Terms and all
        applicable laws at all times; (c) have the legal authority to form a
        binding contract with Tango; and (d) are physically located in a
        jurisdiction where the use of the Services is permitted by law.
      </Paragraph>
      <Paragraph>
        Tango reserves the right to terminate or suspend your account, delete
        any content or information you have submitted, and prohibit you from
        using the Services, with or without notice if it has any reason to
        believe that you are under the Age of Consent in your jurisdiction, or
        that the Services are prohibited in your jurisdiction.
      </Paragraph>
      <Paragraph>
        Accessing and utilizing the Services may have limited compatibility
        across different devices, platforms, and operating systems. It is your
        sole responsibility to obtain and maintain compatible devices required
        to access and use our Services, as updated from time to time.
      </Paragraph>
      <Headline level={2}>Account Registration</Headline>
      <Paragraph>
        To use and access certain features of the Services, you may be required
        to register with Tango and create an account. During the registration
        process, you may be asked to provide certain information, including,
        without limitation: (a) a unique username and password; (b) contact
        information, such as your name, phone number, and email address; (c)
        payment and billing information; and (d) any additional information
        requested on the account registration form.
      </Paragraph>
      <Paragraph>
        <b>
          Please note that only one account may be registered per user, and the
          use of multiple accounts to access and use our Services is not
          permitted.
        </b>
      </Paragraph>
      <Paragraph>
        You are solely responsible for keeping your account information secure.
        Please do not share your account information with anyone. By accepting
        these Terms and registering an account, you agree to notify us
        immediately, if you have lost control of your account information or you
        suspect there is unauthorized or suspicious activity in your account, as
        detailed in the "Contact Us" section. However, you remain fully liable
        for any actions taken with respect to your account, regardless of
        whether these actions were taken by you or an unauthorized third party
        that has gained access to your account.
      </Paragraph>
      <Paragraph>
        As the holder of your account, you are solely responsible for complying
        with these Terms, and only you are entitled to all benefits accruing
        thereto. You may not allow any other person to (a) access your account;
        or (b) access the Services through your account; and (c) accept or use
        Coins, other Digital Items or Diamonds from your account.
      </Paragraph>
      <Paragraph>
        You understand and acknowledge that your account is strictly personal
        and may not be transferred to any other person or account.
      </Paragraph>
      <Paragraph>
        We reserve the right to verify your registration details, such as name,
        address, age, and payment methods used, at any time, by requesting
        certain documents. These documents shall typically include an identity
        card, proof of address such as a utility bill, and proof of your payment
        method. If deemed necessary, we may request that the said document
        copies be notarized, meaning that the documents are stamped and attested
        by a public notary. In the event our requests for documents are not
        completed by you, Tango may at its sole discretion terminate your
        account, and withhold any funds or virtual items (including Virtual
        Items and Diamonds, as defined below) that are present therein.
      </Paragraph>
      <Paragraph>
        You agree to update any provided information or data or to provide
        additional items as part of ongoing efforts to prevent money laundering
        and illegal and fraudulent activities, and/or to comply with any other
        policies or protocols we elect to put in place. You agree that all
        information and data that you provide to us either at the time you
        register for an account or at any subsequent time will be truthful,
        accurate and verifiable in all respects and, by providing such
        information and data, you consent to us submitting it to third party
        providers such as age verification and identification services to verify
        your account.
      </Paragraph>
      <Paragraph>
        We reserve the right at any time to investigate your account, including
        performing background checks and credit checks, in order to ensure
        compliance with these Terms and our obligations and to ensure that no
        improper or illegal activity is or has taken place. In the event that we
        determine that your account has been involved in any illegal, fraudulent
        or improper activity, we reserve the right to terminate immediately your
        account without notice or liability.
      </Paragraph>
      <Headline level={2}>License Grant</Headline>
      <Paragraph>
        Subject to your continuing compliance with these Terms, Tango grants you
        a worldwide conditional, limited, non-commercial (except as permitted by
        Tango), non-exclusive, non-sublicensable, non-assignable,
        non-transferable, freely revocable license to access and use the
        Service.
      </Paragraph>
      <Paragraph>
        Our Services may incorporate software components owned by or licensed by
        third parties, which may include open-source software. In such cases,
        the terms of the respective open-source license or other third-party
        license will govern the use of these components, regardless of the terms
        outlined in these Terms. It is important to note that nothing in these
        Terms is designed to restrict any rights under or to grant rights
        conflicting with, the terms of any applicable open-source license or
        other third-party license pertaining to any such components.
      </Paragraph>
      <Headline level={2}>
        Intellectual Property Rights; Ownership; Respecting Copyright
      </Headline>
      <Paragraph>
        All title, ownership and intellectual property rights in and to the
        Services, images, text, graphics, illustrations, trademarks, brands,
        service marks, trade dress, copyrights, photographs, audio, videos and
        music, logos, designs, Digital Item (as defined below), and any part
        thereof, including derivative works, of Tango and other companies
        providing services to Tango, are the property of Tango or their
        respective owners. As a user of the Services, you agree not to use,
        copy, reproduce, publish or borrow any of the aforementioned content or
        trademarked work without explicit permission from Tango or the owner
        thereof. Except as explicitly provided herein, nothing in these Terms
        shall be deemed to grant you or any other party a license in or under
        any such intellectual property rights, and you agree not to sell,
        license, rent, modify, distribute, copy, reproduce, transmit, publicly
        display, publicly perform, publish, adapt, edit or create derivative
        works from any materials or content accessible on the Services.
      </Paragraph>
      <Paragraph>
        You may choose to, or we may invite you to submit comments or feedback
        about the Services, including without limitation about how to improve
        the Services (<b>"Feedback"</b>). You understand that your Feedback is
        gratuitous and unsolicited, and will not place Tango under any fiduciary
        or other obligation. By submitting any Feedback, you represent and
        warrant that: (a) you have the right to disclose the Feedback; (b) the
        Feedback does not violate the rights of any other person or entity; and
        (c) your Feedback does not contain the confidential or proprietary
        information of any third party.
      </Paragraph>
      <Paragraph>
        By sending us any Feedback, you further: (a) agree that we are under no
        obligation of confidentiality, express or implied, with respect to the
        Feedback; (b) acknowledge that we may have something similar to the
        Feedback already under consideration or in development; (c) grant us an
        irrevocable, non-exclusive, royalty-free, perpetual, worldwide license
        to use, modify, prepare derivative works, publish, distribute and
        sublicense the Feedback; and (d) irrevocably waive, and cause to be
        waived, against Tango any claims and assertions of any moral rights
        contained in such Feedback. This Feedback section will survive any
        termination of your account or the Services.
      </Paragraph>
      <Paragraph>
        Except as expressly set forth herein, no right or license is granted
        hereunder, express or implied, to any intellectual property rights, and
        your use of the Services does not convey or imply the rights to use the
        Services in combination with any other information or products.
      </Paragraph>
      <Paragraph>
        Tango diligently addresses notices of alleged copyright infringement in
        accordance with various legal frameworks, such as the US Digital
        Millennium Copyright Act (<b>"DMCA"</b>), the E-Commerce Directive, and
        relevant local laws in the EU and other jurisdictions. We hold a strong
        commitment to respecting the intellectual property rights of others and
        expect the same from our users.
      </Paragraph>
      <Paragraph>
        If you are a copyright owner and believe that content posted on the
        Services violates your copyright, please follow our{" "}
        <LinkToCopyright>copyright guidelines</LinkToCopyright>. These
        guidelines provide detailed information about our policies, what should
        be included in your notice, and where to submit it.
      </Paragraph>
      <Headline level={2}>Content Obligations</Headline>
      <Paragraph>
        Tango allows you to access and view live streams and pre-recorded
        audio-visual content created and broadcasted by other users (
        <b>"Broadcaster Content"</b>) across public, premium, and private modes.
        Additionally, you can enjoy various services such as chat, forum
        postings, photo sharing, voice and text features, and other interactive
        and entertainment features (together with Broadcaster Content{" "}
        <b>"Content"</b>). Entry to premium or private modes is at the
        discretion of the user and the broadcaster. Please note that the
        opinions expressed by other users of the Services do not necessarily
        reflect our values or opinions.
      </Paragraph>
      <Paragraph>
        To take advantage of features enabling you to upload or transmit Content
        through the Services or communicate with other users, you are required
        to adhere to the guidelines outlined in these Terms. We reserve the
        right (but not the obligation) to remove, edit or refuse to transmit any
        Content for the purpose of enforcing these Terms, or for any other
        reason in Tango’s sole discretion.
      </Paragraph>
      <Paragraph>
        You bear full responsibility for your Content and the outcomes of
        posting or publishing it. By sharing Content, you affirm and warrant
        that: (a) you are the creator or own or control all rights in and to the
        Content or otherwise have sufficient rights and authority to grant the
        rights granted herein; (b) your Content does not and will not: (1)
        infringe, violate, or misappropriate any third-party right, including
        any copyright, trademark, patent, trade secret, moral right, privacy
        right, right of publicity, or any other intellectual property or
        proprietary right, or (2) defame any other person; (3) contain any
        viruses, adware, spyware, worms, or other harmful or malicious code.
      </Paragraph>
      <Paragraph>
        Any Content transmitted by you will be considered non-confidential and
        non-proprietary, and treated as such by Tango, and may be used by Tango
        in accordance with these Terms without notice to you and without any
        liability. You waive any rights to prior inspection or approval of any
        marketing or promotional materials related to any Content. You also
        waive any and all rights of privacy, publicity or any other rights of a
        similar nature in connection with Content, or any portion thereof. To
        the extent any moral rights are not transferable or assignable, you
        hereby waive and agree never to assert any and all moral rights, or to
        support, maintain or permit any action based on any moral rights that
        you may have in or with respect to any Content you transmit or display
        through the Services.
      </Paragraph>
      <Paragraph>
        While Tango employs reasonable security measures to protect Content from
        unauthorized copying and distribution, it cannot guarantee complete
        immunity against such actions by third parties. By uploading Content,
        you acknowledge and accept that Tango will not be held liable for any
        unauthorized copying, use, or distribution of Content by third parties.
        You release Tango from any claims arising from such unauthorized
        activities. our security measures are provided on an "as-is" basis, with
        no warranties or assurances that they will withstand attempts to
        circumvent them.
      </Paragraph>
      <Paragraph>
        If you transmit or share your Content in the Services or otherwise share
        Content with or link Content to the Services, for example, through
        Reference Sites or other third-party applications connected to your
        Tango account, you represent and warrant that you have all rights
        necessary to expressly grant, to Tango, its licensors and other Tango
        partners a royalty-free, sublicensable, transferable, perpetual,
        irrevocable, non-exclusive, worldwide license to use, host, store,
        reproduce, modify, adapt, publish, list information regarding, edit,
        translate, distribute, syndicate, publicly perform, publicly display,
        transmit, communicate to the public, make available, make derivative
        works of, and otherwise exploit and use, all such Content and without no
        limitation, any name, voice, image and/or likeness as contained in
        Content, in whole or in part, and in any form, media or technology,
        whether now known or hereafter developed, for use in connection with the
        Services and Tango’s (and its successors’ and affiliates’) businesses,
        including without limitation for promoting and redistributing part or
        all of the Services (and derivative works thereof) in any media formats
        and through any media channels. For clarity, the rights granted by you
        in this section to Tango include the right to reproduce User Content on
        a royalty-free basis and that Tango will have no obligation to pay
        royalties to you or any third party involved in the creation of Content.
        Further, you understand that the license granted by you to Tango
        hereunder will survive any termination of your use of the Services.
      </Paragraph>
      <Paragraph>
        You acknowledge and agree that Tango may collect, use and share
        information related to your use of the Services as described in the{" "}
        <LinkToPrivacyPolicy /> including certain information contained in the
        devices to which you have downloaded the Services for purposes of your
        use of the Services, such as the address books and unique phone
        identifiers (IMEI, Google Advertisements ID, or other) contained in such
        devices.
      </Paragraph>
      <Headline level={2}>
        Prohibited Content, Content Moderation, Complaints and Misuse
      </Headline>
      <Paragraph>
        By using our Services, you acknowledge that we implement and utilize
        content moderation mechanisms, both automated and manual. We explicitly
        prohibit content falling within the following categories, as defined in
        our Community Guidelines (<b>"Prohibited Content"</b>):
      </Paragraph>
      <ol className={styles.alphabeticalBracketed}>
        <ListItem>
          <b>Illegal Activity:</b> Content promoting or facilitating illegal
          activities, including violence, terrorism, drug use, or hate crimes;
        </ListItem>
        <ListItem>
          <b>Hate Speech:</b> Content that attacks a person or group on the
          basis of race, ethnicity, religion, nationality, disability, sexual
          orientation, gender identity, or other protected characteristics;
        </ListItem>
        <ListItem>
          <b>Harassment and Bullying:</b> Content that threatens violence
          against an individual or group or that is intended to intimidate,
          threaten, or abuse another person. This includes cyberbullying and
          doxing;
        </ListItem>
        <ListItem>
          <b>Child Sexual Abuse Content (CSAM):</b> Any content depicting the
          sexual abuse of a minor. We have zero tolerance for CSAM;
        </ListItem>
        <ListItem>
          <b>Violent threats:</b> content that threatens violence against an
          individual or group;
        </ListItem>
        <ListItem>
          <b>Intellectual Property Infringement:</b> Content that violates
          intellectual property rights, such as unauthorized streaming of
          copyrighted material;
        </ListItem>
        <ListItem>
          <b>Misinformation and Disinformation:</b> Spreading demonstrably false
          or misleading information, particularly regarding public health or
          safety;
        </ListItem>
        <ListItem>
          <b>Spam and Malicious Content:</b> Unsolicited commercial messages,
          phishing attempts, or malware distribution;
        </ListItem>
        <ListItem>
          <b>Promotion of Self-Harm:</b> Content that encourages or glorifies
          self-harm or suicide.
        </ListItem>
      </ol>
      <Paragraph>
        You acknowledge and agree that to utilize and enjoy our Services,
        including viewing Content or utilizing other features, such as enabling
        you to upload or transmit Content through the Services or communicate
        with other users or broadcasters, you must adhere to the guidelines
        outlined in these Terms and our Community Guidelines. If you are a
        broadcaster, you are also required to adhere to our Broadcaster Terms.
      </Paragraph>
      <Paragraph>
        Tango may, at its discretion, utilize automated, semi-automated tools,
        and human checks of Broadcast Content and Content. However, you
        acknowledge and agree that we may not be able to identify all Prohibited
        Content, and we make no warranties to you regarding any Broadcast
        Content or Content. We reserve the right, at our discretion and at any
        time, to remove, refuse to share, transmit, upload, display, or publish
        any material, content, or information provided by you or any Content for
        the purpose of enforcing these Terms or for any other reason deemed
        appropriate by us.
      </Paragraph>
      <Paragraph>
        We encourage users to report any content that violates these Terms or
        our policies using the designated reporting tools within our Services.
      </Paragraph>
      <Paragraph>
        <b>
          The following section shall apply with respect to users or
          broadcasters located in the EU.
        </b>
      </Paragraph>
      <Paragraph>
        We have implemented various measures to counter the presence of illegal
        and Prohibited Content on our Services to ensure compliance with
        European Regulation 2022/2065, known as the Digital Services Act (
        <b>"DSA"</b>).
      </Paragraph>
      <Paragraph>
        We take our content moderation obligations very seriously and we ensure
        at all times that our employees receive adequate guidelines and training
        on how to handle and respond to your notices and complaints. In this
        regard, we have implemented escalation processes to ensure that any
        notices or complaints are appropriately reviewed and, if necessary,
        escalated to the relevant department for further investigation and
        action. This escalation process aims to uphold the integrity of the
        content moderation system and address any issues promptly and
        effectively. However, you accept that we do not guarantee the accuracy,
        completeness, or timeliness of our moderation efforts.
      </Paragraph>
      <Paragraph>
        If Tango determines that you acted in violation of these Terms or
        Tango's Policies, including by uploading or submitting Prohibited
        Content, Tango may, at its sole discretion, apply restrictions on your
        use of Tango's Services, depending on the frequency and severity of your
        violations, including.
      </Paragraph>
      <Paragraph>
        <b>Removing the content:</b> If Tango determines that your content
        violates our Terms or Policies, including the submission of Prohibited
        Content, we may remove the offending content from our platform.
      </Paragraph>
      <Paragraph>
        <b>Restrictions:</b> Implementing restrictions on visibility, etc.: In
        addition to content removal or account suspension/termination, Tango may
        implement restrictions on the visibility of your content or your
        account. This could involve limiting access to certain features or areas
        of the platform.
      </Paragraph>
      <Paragraph>
        <b>Termination or suspension of your account:</b> Depending on the
        severity of the violation, Tango reserves the right to either suspend
        your account for a temporary period or terminate it entirely. Suspension
        may occur if the violation is deemed less severe, while termination is
        reserved for more egregious violations.
      </Paragraph>
      <Paragraph>
        These measures are implemented to maintain the safety, integrity, and
        quality of our platform for all users.
      </Paragraph>
      <Paragraph>
        <b>Submitting Complaint Procedure:</b> You acknowledge that we provide
        you and the broadcaster with effective mechanisms to report any
        Prohibited Content (e.g., report button, through the customer service
        email, etc.) shared, transmitted, uploaded, displayed or published
        within our Services. By reporting the alleged Prohibited Content, shall
        assist us in promptly identifying illegal material, including Prohibited
        Content. You acknowledge that we will review all reported alleged
        illegal content and, where applicable, take the necessary action to
        remove it if it violates our Terms, based on the nature of your
        complaint, which may include, among others, removing the Prohibited
        Content, issuing a warning to you or the broadcaster, or
        suspending/banning broadcaster’s or your account. If we accept your
        complaint and identify any Prohibited Content or any illegal content
        that violates our Terms, we will promptly remove it and notify you of
        our actions via e-mail or your account.
      </Paragraph>
      <Paragraph>
        In case we have found that a user of the Services utilizes its account
        to disseminate manifestly illegal content, including Prohibited Content,
        we reserve the right to suspend their access to the Services for a
        reasonable period of time, as will be determined on a
        case-by-case-basis, taking into consideration relevant factors such as
        prior posts, the nature of the content, and other pertinent
        circumstances.
      </Paragraph>
      <Paragraph>
        You acknowledge and agree that we have the right to fully cooperate with
        any law enforcement authorities or court order requesting or directing
        us to disclose the identity or other information of anyone posting any
        material, content or information on the Service. In this regard, by
        acknowledging this, you waive and release us from any liability
        associated with disclosing data to such law enforcement entities.
      </Paragraph>
      <Paragraph>
        <b>Complaint Handling Procedure:</b> You acknowledge and agree that you
        may lodge a complaint against any of our decisions related to: (a) any
        action taken against you, your account, or any material, content or
        information that you attempted to or uploaded on the Services; (b) any
        notice you submitted for content reporting or removal.
      </Paragraph>
      <Paragraph>
        In this regard, you understand that the deadline for submission of a
        complaint is six (6) months after notification of the respective
        decision has been communicated to you.
      </Paragraph>
      <Paragraph>
        You have the option to lodge a complaint via the email linked to your
        account or the email address provided in the applicable decision
        notification. You understand that your complaint must contain adequate
        information for us to conduct an investigation, including an explanation
        of why you believe your complaint is justified. Failure to provide
        sufficient details may result in the rejection of your complaint.
      </Paragraph>
      <Paragraph>
        Please note that we will review all complaints in a non-discriminatory,
        non-arbitrary, diligent and timely manner. Note that your complaints
        will always be subject to human review. We may reverse the previous
        decision if the complaint sufficiently demonstrates that, inter alia:
        (a) the alleged material, content or information did not in fact violate
        applicable law, third party rights or these Terms; (b) our prior
        decision was unjustified, disproportionate in any other manner.
      </Paragraph>
      <Paragraph>
        <b>Misuse and Abuse:</b> By submitting any material information on our
        Services, including any report alleging illegal content or complaint on
        the Services for content moderation, you agree not to abuse such
        features or misuse our systems. We will promptly issue notification
        letters where legally required if we identify any abuse of our reporting
        mechanisms or misuse of our Services. You acknowledge and agree that any
        abuse or misuse of any mechanisms, systems or processes within our
        Services may result in appropriate action, including but not limited to
        the suspension or termination of your account, for example, if your
        notices or complaints sent are manifestly unfounded, without prejudice
        of a first warning when legally required.
      </Paragraph>
      <Paragraph>
        You recognize that misuse or abuse encompasses various behaviors, such
        as: (a) repeatedly submitting unfounded notices or complaints regarding
        illegal or improper content, including Prohibited Content, particularly
        over a specific timeframe or for the purpose of harassment,
        intimidation, or any other malicious intent and so regardless the
        content’s legality; (b) intentionally circumventing geographic or other
        restrictions to access, distribute, share, or post content; (c)
        generating a substantial number of reports lacking sufficient or valid
        information compared to the total notices or complaints made within a
        set period; (d) repeatedly infringing our Terms.
      </Paragraph>
      <Paragraph>
        Before undertaking any necessary actions in response to misuse or abuse,
        we will evaluate each case individually. This evaluation will take into
        account factors such as the nature, frequency, context, and potential
        harm caused by the misuse or abuse.
      </Paragraph>
      <Paragraph>
        In case a User of the Services abuses the notice or complaint-handling
        mechanisms, we reserve the right to suspend their ability to utilize
        such mechanisms.
      </Paragraph>
      <Paragraph>
        <b>Out-of-court Dispute Resolutions Mechanisms:</b> In case you reside
        in the EU, you have the right to choose a dispute resolution body, that
        have been certified in accordance with the DSA, to assist in resolving
        disputes related to our decision on your uploaded content, notices or
        complaints that you submitted to us.
      </Paragraph>
      <Paragraph>
        We may, however, decline our co-operation with your chosen Dispute
        Resolution Body if: (a) the dispute has already been resolved by court
        or another dispute resolution body; (b) you have contacted the dispute
        resolution body after six (6) months from our decision notification
        without previously using our complaint handling mechanism.
      </Paragraph>
      <Paragraph>
        You acknowledge that per the DSA any decisions taken by such dispute
        settlement bodies shall not be binding on either you or us.
      </Paragraph>
      <Paragraph>
        <b>Point of Contact for matters related to the DSA:</b> In accordance
        with the DSA, <MailToLegal />, is the designated, single point of
        contact for communications with recipients of the Services offered
        herein, as well as for the European Union Member State authorities, the
        Commission, and the Board for the Digital Services. Please conduct all
        communication in English.
      </Paragraph>
      <Headline level={2}>Use Restrictions and User Representations</Headline>
      <Paragraph>
        You represent and warrant that you have full authorization to utilize
        the Service and consent to be bound by Tango's Terms. You commit to
        adhering completely to all applicable laws, regulations, statutes,
        ordinances, and these Terms.
      </Paragraph>
      <Paragraph>
        You undertake not to engage in fraudulent activities, or attempts
        thereof, against Tango or other users, and you shall not act in bad
        faith in your use of the Service. If Tango determines that you do act in
        bad faith or in violation of these Terms or Tango's Policies, including
        our Community Guidelines and Broadcaster Agreement, Tango may, at its
        sole discretion, terminate your account and prohibit you from using the
        Services.
      </Paragraph>
      <Paragraph>You further represent and agree that you shall not:</Paragraph>
      <ol className={styles.alphabeticalBracketed}>
        <ListItem>
          Create an account, access, or use the Services if you do not meet the
          Age of Consent;
        </ListItem>
        <ListItem>
          Upload, post, transmit, or otherwise distribute material or
          information that contains or links to materials that conflict with our
          Community Guidelines;
        </ListItem>
        <ListItem>
          Use, upload, or transmit (or attempt to do so) files containing
          viruses, Trojan horses, worms, time bombs, cancelbots, RATs, keyboard
          loggers, spyware, adware, or any other malicious or invasive code or
          program, corrupted files or data, or any other similar software or
          programs that may damage the operation of the Service or the computers
          of other users;
        </ListItem>
        <ListItem>
          Transmit spam, chain letters, materials related to any political
          campaigns, commercial solicitations, mass mailings, or any form of
          spam or other unsolicited texts or emails;
        </ListItem>
        <ListItem>
          Infringe upon the contractual, personal, intellectual property, or
          other rights of any party by using, uploading, transmitting,
          distributing, or making available any information or material through
          the Service in a manner that infringes any copyright, trademark,
          patent, trade secret, or other right of any party;
        </ListItem>
        <ListItem>
          Collect or harvest any personally identifiable information, including
          account names, from the Services;
        </ListItem>
        <ListItem>
          Create false personas, multiple identities, multiple user accounts,
          set up an account on behalf of someone other than yourself, use bots
          or other automated software programs to defraud or violate these Terms
          and/or the terms of service of any third-party applications or social
          networks through which the Service is accessed;
        </ListItem>
        <ListItem>
          Attempt to obtain or reveal passwords or other private information
          from other users, including personally identifiable information,
          identification documents, financial information, or any information
          that may be used to track, contact, or impersonate another individual;
        </ListItem>
        <ListItem>
          Use the Service to design, assist, develop, distribute, use, or
          publicly inform other users of "auto" software programs, "macro"
          software programs, "cheat utility" software programs or applications,
          hacks, mods, or any other unauthorized third-party software designed
          to allow the user to cheat or obtain an unintended advantage while
          using the Service or modify or interfere with the Service or any Tango
          features and Tango usage experience;
        </ListItem>
        <ListItem>
          Exploit, distribute, or publicly inform other members of any errors,
          miscues, bugs, or technical malfunctions which give an unintended
          advantage;
        </ListItem>
        <ListItem>
          Engage in any activity related to Digital Items in a manner that
          violates these Terms, including transferring Digital Items to other
          individuals or user parties or entities, selling or re-selling Digital
          Items, or fraudulently obtaining or acquiring Digital Items or other
          products or services;
        </ListItem>
        <ListItem>
          Intentionally or unintentionally use the Service in connection with
          any violation of applicable laws or regulations or encourage or
          promote illegal activities;
        </ListItem>
        <ListItem>
          Interfere with others' ability to enjoy the Service, including making
          any automated use of the system or take any action that imposes an
          unreasonable or disproportionately large load on Tango's
          infrastructure;
        </ListItem>
        <ListItem>
          Reverse engineer, decompile, disassemble, decipher, modify, or
          otherwise attempt to derive the object code or source code for any
          underlying software or other intellectual property used to provide the
          Service;
        </ListItem>
        <ListItem>Create derivative works of the Services;</ListItem>
        <ListItem>
          Use the Services in whole or in part for any purpose except as
          expressly provided herein;
        </ListItem>
        <ListItem>
          Disable or circumvent any access control or related device, process or
          procedure established with respect to the Services.
        </ListItem>
      </ol>
      <Paragraph>
        Any use of the Service in violation of these representations and use
        restrictions is strictly prohibited and may result in the immediate
        termination of your account at Tango's sole discretion. Such violations
        may also subject you to criminal and financial liabilities under
        criminal and civil laws.
      </Paragraph>
      <Paragraph>
        We reserve the right to disclose or report any money laundering or other
        illegal activity to law enforcement and regulatory authorities. Tango
        reserves the right to seek damages and other remedies from you to the
        fullest extent permitted by law.
      </Paragraph>
      <Headline level={2}>
        Third Parties; Third-Party Sites, Products and Services; Links
      </Headline>
      <Paragraph>
        The Services may be incorporated into and may incorporate itself,
        software and other technology owned and controlled by third parties. Any
        such third-party software or technology that is incorporated into the
        Services falls under the scope of these Terms. Portions of the Service
        may include software that we license from third parties, which may
        include open source and related components (
        <b>"Third Party Software"</b>) and is subject to the terms imposed by
        third-party licensors. We do not make any warranty with respect to Third
        Party Software.
      </Paragraph>
      <Paragraph>
        The Services may include links or references to third-party websites or
        applications offering products or services, including games,
        applications, third-party shopping merchants, images, special offers, or
        other events or activities, that are operated and maintained by other
        persons and/or that are not owned by Tango (<b>"Reference Sites"</b>).
        These Terms do not apply to Reference Sites. Please be aware of when you
        visit or use any Reference Sites, and we encourage you to read these
        Terms and privacy policies of any Reference Sites that you visit or use.
      </Paragraph>
      <Paragraph>
        Tango does not assume any responsibility for Reference Sites. Access and
        use of Reference Sites, including the information, materials, products,
        and services on or available through Reference Sites is solely at your
        own risk. You expressly relieve Tango from any and all liability arising
        from your use of any Reference Sites.
      </Paragraph>
      <Paragraph>
        If you have downloaded the App from the Apple, Inc. (<b>"Apple"</b>) App
        Store or if you are using the Services on an iOS device, you acknowledge
        that you have read, understood, and agreed to the following notice
        regarding Apple. These Terms are between you and Tango only, not with
        Apple, and Apple is not responsible for the Services and the content
        thereof. Apple has no obligation whatsoever to furnish any maintenance
        and support services with respect to Services. In the event of any
        failure of the Services to conform to any applicable warranty, then you
        may notify Apple and Apple will refund any applicable purchase price for
        the Services to you; and, to the maximum extent permitted by applicable
        law, Apple has no other warranty obligation whatsoever with respect to
        the Services. Apple is not responsible for addressing any claims by you
        or any third-party relating to the Services or your possession and/or
        use of the Services, including: (a) product liability claims; (b) any
        claim that the Services fail to conform to any applicable legal or
        regulatory requirement; and (c) claims arising under consumer protection
        or similar legislation. Apple is not responsible for the investigation,
        defense, settlement and discharge of any third-party claim that the
        Services and/or your possession and use of the Apps infringe that
        third-party's intellectual property rights. You agree to comply with any
        applicable third-party terms, when using the Services. Apple, and
        Apple's subsidiaries, are third-party beneficiaries of these Terms, and
        upon your acceptance of these Terms, Apple will have the right (and will
        be deemed to have accepted the right) to enforce these Terms against you
        as a third-party beneficiary of these Terms. You hereby represent and
        warrant that (a) you are not located in a country that is subject to a
        U.S. Government embargo, or that has been designated by the U.S.
        Government as a "terrorist supporting" country; and (b) you are not
        listed on any U.S. Government list of prohibited or restricted parties.
      </Paragraph>
      <Headline level={2}>Third Party Fees</Headline>
      <Paragraph>
        For particular devices, Tango may ask your permission to use your native
        SMS application to deliver messages or invitations to people who are not
        registered users of the Services and with whom you choose to
        communicate. Some of these services may charge additional fees and by
        providing your permission, you agree to pay all additional fees.
      </Paragraph>
      <Headline level={2}>Coins; Digital Items</Headline>
      <Paragraph>
        The Services provide a feature where the money deposited by Viewers is
        held as "coins" (<b>"Coins"</b>). These Coins are exclusively intended
        for use within our Services, including participation in various
        features, gifting virtual items, and interacting with broadcasters
        (collectively known as <b>"Digital Items"</b>).
      </Paragraph>
      <Paragraph>
        You acknowledge and agree that regardless of the terminology used, any
        amounts transferred for purchasing Coins and Digital Items are held as a
        deposit on your behalf by a third-party payment provider in escrow until
        you gift them to a broadcaster, if applicable. At the point of gifting,
        the deduction from your Coins balance signifies a payment made by you,
        as outlined in this provision (including the Broadcaster Terms).
      </Paragraph>
      <Paragraph>
        Your use of any Coins and Digital Items must be solely for legitimate
        purposes within the Services and must consistently comply with these
        Terms and all applicable laws. Digital Items hold no real-world monetary
        value and cannot be redeemed or cashed out for "real world", money,
        goods, or any other item of monetary value from Tango or any other
        party. You understand that you have no right or title in the Digital
        Items appearing or originating in the Services, whether "awarded" or
        "purchased" from Tango. Since Coins are held in escrow for you, as long
        as you do not use them to gift a Digital Item or for any other purpose,
        you have the right to request a refund for the money deposited, in
        accordance with Tango's policies, procedures, and discretion. To
        initiate the refund process, please follow the steps provided by our
        customer support. A refund will not be granted if your access to the
        Services has been suspended, restricted, or terminated by you or Tango,
        or if you are suspected of engaging in frivolous or fraudulent claims or
        activities, or where necessary to prevent money laundering or other
        illegal activities.
      </Paragraph>
      <Paragraph>
        Purchases of Digital Items are final and non-refundable, except at
        Tango's sole discretion. You are prohibited from transferring, selling,
        or exchanging Digital Items and Coins outside the Services, or
        attempting to sell, give, or trade them in the real world. Any violation
        of these Terms may result in account termination and legal action. Any
        balance of Coins in your Tango account does not represent real-world
        monetary value.
      </Paragraph>
      <Paragraph>
        By using the Service, you agree to pay for all Digital Items purchased
        and authorize Tango to charge your payment method. You are responsible
        for providing a valid payment method and for any applicable charges,
        including taxes and fees, related to your deposit. Furthermore, when you
        make a deposit or use any other payable features, you acknowledge and
        agree that you shall pay any applicable charges, including taxes and any
        fees (e.g., processing fees), related to your transaction.
      </Paragraph>
      <Paragraph>
        Digital Items obtained on application stores or platforms, including but
        not limited to Apple iOS or Android, will be subject to the payment
        terms and conditions of those platforms. Tango does not have control
        over the payment methods available on those platforms and shall not be
        held liable for payment processing by third parties. Please refer to the
        terms of service of those platforms for further information.
      </Paragraph>
      <Paragraph>
        You may only give Digital Items in accordance with these Terms and via
        designated features within the Services. Once given, Digital Items
        cannot be refunded, undone, or withdrawn. Attempting to transfer or sell
        Digital Items in violation of these Terms may result in account
        termination and legal action.
      </Paragraph>
      <Paragraph>
        Giving Digital Items to broadcasters may result in income for them, and
        broadcasters are solely responsible for paying any taxes on their
        earnings. Completed transactions are confirmed upon receipt of payment,
        and Tango may cancel incomplete transactions at its discretion. Tango
        facilitates the collection and transmission of Digital Items to
        broadcasters but does not act as their agent in procuring or providing
        them. Tango retains no authority to contract on behalf of users or
        broadcasters regarding the giving of Digital Items.
      </Paragraph>
      <Paragraph>
        The ratio of real currency to Coins and Digital Items is determined by
        Tango, and Tango may modify this ratio at any time without notice. Your
        use of the Services constitutes acceptance of the current ratio at the
        time of deposit. Tango may update its terms of sale for Coins and
        Digital Items, and you agree to review these terms before making any
        deposits.
      </Paragraph>
      <Paragraph>
        Users, Broadcasters, and everyone who uses our Services are strictly
        prohibited from engaging in self-gifting activities on our platform.
        Self-gifting refers to the act of a user sending, attempting to send, or
        assisting to send, Coins, Digital Items, or any form of benefit to
        themselves, either directly or indirectly. In the event we detect self-
        gifting behaviors through various monitoring tools, Tango may at its
        sole discretion terminate your account, and withhold any funds or
        virtual items (including Digital Items and Diamonds) that are present
        therein.
      </Paragraph>
      <Paragraph>
        Tango retains the authority to temporarily confiscate Coins from your
        Tango account if it reasonably deems your account to be inactive. An
        account will be classified as dormant if it remains inactive for a
        period of forty-five (45) days (<b>"Dormant Account"</b>). Any
        confiscated Coins will be restored to your account upon reactivation.
      </Paragraph>
      <Paragraph>
        Tango maintains a strict stance against fraud. Please be aware that, in
        addition to pursuing any appropriate legal actions, Tango reserves the
        right to terminate your access to the Services and/or permanently seize
        any Coins from your Tango account that are determined, at Tango's sole
        discretion, to have been obtained through fraudulent or improper means.
        Prior to such confiscation, Tango will notify you to allow for an
        opportunity to appeal via the Services. However, please note that
        Tango's decision, made at its sole discretion, will be final and
        binding.
      </Paragraph>
      <Paragraph>
        If your Tango account balance regarding Coins is inaccurate due to
        system maintenance or technical failures, Tango will make commercially
        reasonable efforts to rectify the situation. Feel free to reach out to
        us regarding your Coin balance, purchases of Digital Items, or gifting
        Digital Items at <MailToSupport />.
      </Paragraph>
      <Headline level={2}>Promotions and Offers</Headline>
      <Paragraph>
        From time to time, we may offer limited-time promotions, contests, and
        special offers (<b>"Promotion"</b>). Please review the official rules
        associated with such Promotions and any other terms and conditions that
        apply to such Promotions. They will apply in addition to these Terms.
      </Paragraph>
      <Headline level={2}>
        Availability and New Versions of the Services
      </Headline>
      <Paragraph>
        Tango may, with or without prior notice, stop providing the Services and
        any accompanying feature to you or to users generally, or create usage
        limits for the Services. Tango, in its sole discretion, reserves the
        right to add additional features or functions, or to modify or provide
        programming fixes, updates and upgrades, to the Services without notice.
        Tango has no obligation to make available to you any additional features
        or functions or any modifications, updates, support, maintenance or
        subsequent versions of the Services. You may have to agree to a renewed
        version of some or all of the Terms in the event you want to download,
        install or use any additional features or functions or any
        modifications, updates or new versions of the Services. You acknowledge
        that Tango may automatically issue any additional features or functions
        or modifications, updates or upgraded versions of the Services and,
        accordingly, may modify, update or upgrade the version of the Services
        that you are using or have installed on your Device. You hereby agree
        that your Device may automatically request and/or receive such
        modifications, upgrades or updates.
      </Paragraph>
      <Headline level={2}>Storage and deletion of your Content</Headline>
      <Paragraph>
        Storage space is inherently limited and as a result we are careful not
        to overload our servers. Accordingly, you acknowledge and agree that
        Tango is not under any obligation to preserve, provide access to or
        return to you any Content and that Tango shall have no responsibility
        for the modification, loss, deletion or destruction of any Content,
        including any stored Content. You further understand and agree that
        Tango may remove certain Content from its storage systems periodically
        at its discretion without notice to you.
      </Paragraph>
      <Headline level={2}>Termination</Headline>
      <Paragraph>
        You may terminate your use of the Services at any time by uninstalling
        and deleting the Services from all of your devices. If you have
        subscribed to any premium services, you agree that you are solely
        responsible for directly terminating all future payment obligations you
        may have with any Store and/or payment processing service in connection
        with the premium services. You may choose to delete your account prior
        to deleting the Services from your device. Tango will then delete all
        your content and account details. Tango reserves to right to retain any
        of Your data that necessary to comply with any applicable law or legal
        obligation.
      </Paragraph>
      <Paragraph>
        Tango reserves the right at its sole discretion to restrict, suspend,
        terminate, modify, or delete accounts or access to and use the Services
        and any other features without any obligation to compensate you for any
        resulting losses. Without limiting any other remedies, Tango may remove,
        block, suspend, at its sole discretion, any Content you share on the
        Service, as well as modify, limit, suspend, discontinue, or terminate
        any of the Terms, access, and/or your use of all or any part of the
        Services with immediate effect automatically, with or without notice and
        without recourse to the courts or other tribunals for any reason or for
        no reason, including without limitation if Tango believes that you are:
      </Paragraph>
      <ol className={styles.alphabeticalBracketed}>
        <ListItem>In breach of any of the terms of any of the Terms;</ListItem>
        <ListItem>
          Creating problems or legal and/or regulatory liabilities (actual or
          potential);
        </ListItem>
        <ListItem>
          Delinquent with respect to any charges due for a Premium Service;
        </ListItem>
        <ListItem>
          Infringing a third party’s intellectual property rights;
        </ListItem>
        <ListItem>
          Displaying, transmitting, or sharing any Prohibited Content;
        </ListItem>
        <ListItem>
          Engaging in fraudulent, immoral, or illegal activities;
        </ListItem>
        <ListItem>
          Illegal or improper use of your account or the Services or improper
          use of Tango's intellectual property;
        </ListItem>
        <ListItem>Endangering Tango's user community;</ListItem>
        <ListItem>Dormant Account for 45 days or longer.</ListItem>
      </ol>
      <Paragraph>
        You agree that Tango is under no obligation to provide the Services,
        including without limitation any premium services, and that neither
        Tango nor any third party shall be liable to you or to any other party
        for any limitation, suspension, discontinuance, termination, or
        modification of the Services.
      </Paragraph>
      <Headline level={2}>Indemnification</Headline>
      <Paragraph>
        You agree to indemnify, defend and hold harmless Tango, its licensors,
        its agents, its partners, and their respective affiliates, officers,
        directors, employees, contractors, and suppliers (including Tango,
        collectively the <b>"Tango Parties"</b>), from and against any and all
        claims, obligations, actions, losses, liability, damages and costs,
        including but not limited to reasonable attorneys’ fees, arising from
        your use of the Services or access to the Services, including, without
        limitation: incurred by such parties, in connection with or arising out
        of (a) your violation of these Terms or any applicable law, rule or
        regulation, whether or not referenced herein, (b) Infringement of ant
        third-party rights; (c) misuse of the Services (d) your Content or other
        communication transmitted via the Services; (e) any claims arising from
        user submissions or content submitted via your account; (f) unauthorized
        access to the Services using your credentials; (g) any taxes related to
        your purchase or use of Services (excluding Tango's income taxes); (h)
        any other third-party claim related to your use of the Services.
      </Paragraph>
      <Paragraph>
        Tango reserves the right to assume exclusive defense and control of
        claims for which you are obligated to indemnify Tango, and you agree to
        cooperate with Tango's defense efforts. Tango will make reasonable
        efforts to notify you of any such claims.
      </Paragraph>
      <Headline level={2}>Warranty Disclaimer</Headline>
      <Paragraph>
        By accessing, using, or downloading the Service, you acknowledge and
        agree that your utilization of the Service, any available Service's
        feature and inclusive of all content, shall be solely at your own risk
        and is provided on an "as is" basis. Tango Parties disclaim all
        warranties, conditions, or other terms of any kind, whether explicit or
        implied, in connection with the Service and your use thereof, including
        but not limited to implied warranties of merchantability, title, quiet
        enjoyment, fitness for a particular purpose, or non-infringement, as
        well as warranties regarding usefulness, authority, accuracy,
        completeness, and timeliness.
      </Paragraph>
      <Paragraph>
        Tango Parties makes no warranties or representations that the Services
        provided by us or by any linked sites will be uninterrupted, up-to-date,
        complete or free of errors, viruses or other harmful components, and do
        not warrant that any of the foregoing will be corrected. Tango Parties
        assumes no liability for:
      </Paragraph>
      <ol className={styles.alphabeticalBracketed}>
        <ListItem>
          Error, omission, deletion, defect, or any failure to perform, error,
          omission, interruption, deletion, defect or delay in operation;
        </ListItem>
        <ListItem>
          Personal injury or property damage resulting from Service access and
          usage;
        </ListItem>
        <ListItem>Unauthorized access to your account;</ListItem>
        <ListItem>
          Interruption or cessation of transmission to or from the Service;
        </ListItem>
        <ListItem>
          Transmission of harmful components by third parties, such as bugs,
          viruses, trojan horses, or similar;
        </ListItem>
        <ListItem>
          Malfunctions in computer systems, hardware, software, or program
          operations, or any other errors, failures, or delays arising from
          Service access or usage;
        </ListItem>
        <ListItem>
          Quality, accuracy, completeness, or validity of any information, data,
          any feature or component of the Service;
        </ListItem>
        <ListItem>
          Suitability of the Service to meet your requirements, or the security
          of transmissions or data.
        </ListItem>
      </ol>
      <Paragraph>
        You understand and agree that the use of the Services is at your own
        discretion and risk and that you will be solely responsible for any
        damage and any losses that results from the Service or any of the
        related services, including any loss of data, loss of Coins, or damage
        to your property (including your device and computer system). You
        understand and agree that any loss of any kind you incur while using the
        Services and any other related services and features is solely your
        responsibility. The company disclaims any liability for any such losses.
        You expressly waive any claims or demands of any kind against the
        company for any losses incurred as a result of your use of the Services
        and any other related services and features.
      </Paragraph>
      <Paragraph>
        By accessing or using the Service, you agree that Tango is not
        responsible for any damage, loss, or injury resulting from unauthorized
        access or use, such as hacking or tampering. Unauthorized access
        attempts, interference with Service procedures, or intentional damage to
        the Service may result in civil and/or criminal prosecution, immediate
        termination of participation, and forfeiture of any Digital Items. Any
        fraudulent activity, including automated or fraudulent methods of
        access, will lead to prosecution, participation termination, and Digital
        Items forfeiture.
      </Paragraph>
      <Headline level={2}>Limitations of Liability</Headline>
      <Paragraph>
        To the maximum extent permitted by applicable law, in no event shall the
        Tango Parties be liable to you or any third party for: (a) Any special,
        direct, indirect, incidental, punitive, exemplary, or consequential
        damages, including lost profits, data, goodwill, legal fees, expert
        fees, substitute service costs, lost opportunities, or other expenses,
        arising from Service reliance or usage; (b) Third-party conduct,
        including other user actions or external site operators.
      </Paragraph>
      <Paragraph>
        You agree to release, discharge, defend, indemnify, and hold Tango and
        its affiliates, subsidiaries, directors, officers, agents, contractors,
        partners, and employees harmless from and against any loss, liability,
        claim, demand, damages, costs, and expenses, including reasonable
        attorney's fees, arising from:
      </Paragraph>
      <ol className={styles.alphabeticalBracketed}>
        <ListItem>Your Service use or misuse;</ListItem>
        <ListItem>Violation of these Terms;</ListItem>
        <ListItem>
          Breach of representations, warranties, and covenants;
        </ListItem>
        <ListItem>
          Violation of third-party rights, including copyright, property, or
          privacy;
        </ListItem>
        <ListItem>
          Claims of user submissions causing third-party damage;
        </ListItem>
        <ListItem>
          User Content or Content shared on or through the Service.
        </ListItem>
      </ol>
      <Paragraph>
        However, in no event shall the aggregate liability of the Tango Parties
        exceed the amount you paid to Tango for any products or services
        purchased from Tango on any of the services during the six (6) month
        period immediately preceding any claim. The foregoing limitations will
        apply even if the above-stated remedy fails in its essential purpose. As
        some jurisdictions do not allow some of the exclusions or limitations
        set forth above, some of these exclusions or limitations may not apply
        to you. In such event the liability of the Tango Parties will be limited
        to the maximum extent possible under applicable law.
      </Paragraph>
      <Paragraph>
        You and Tango agree that any cause of action arising out of or related
        to any of the Services must commence within one (1) year after the cause
        of action accrues. Otherwise, such cause of action is permanently
        barred.
      </Paragraph>
      <Headline level={2}>Export and Sanctions</Headline>
      <Paragraph>
        The Services may only be operated, exported or re-exported in compliance
        with all applicable laws and export regulations of the United States and
        the country in which you obtained them. The Services are specifically
        subject to the U.S. Export Administration Regulations; diversion
        contrary to United States law is prohibited. You may not export,
        directly or indirectly, the Services or technical data licensed
        hereunder or the direct product thereof to any country, individual or
        entity for which the United States Government or any agency thereof, at
        the time of export, requires an export license or other government
        approval, without first obtaining such license or approval.
      </Paragraph>
      <Paragraph>
        You are required to comply with all applicable economic sanctions laws,
        including those administered by the U.S. Department of the Treasury
        Office of Foreign Assets Control (<b>"OFAC"</b>), and may not use,
        export, or transfer any portion of the Services or any related technical
        information or materials directly or indirectly except as authorized by
        such sanctions laws. These laws generally prohibit or restrict
        transactions involving: certain countries or regions including Crimea,
        so-called Donetsk People's Republic, so-called Luhansk People's
        Republic, Cuba, Iran, North Korea, and Syria, as well as any individual
        or entity from, located in, ordinarily resident in, or affiliated with
        the governments of those countries or regions; certain individuals or
        entities including those identified on lists published by OFAC such as
        the Specially Designated Nationals and Blocked Persons (<b>"SDN"</b>)
        List; nationals of Cuba; and the Government of Venezuela. Users may only
        access the Services in compliance with these laws. Persons who are from,
        located in, or ordinarily resident in countries or regions subject to
        U.S. sanctions, who are associated with or acting on behalf of the
        governments of those countries or regions, who are SDNs or other denied
        or blocked parties under U.S. law, or who are otherwise subject to
        sanctions are prohibited from accessing or using the Services absent a
        license or other government approval or authorization. Tango has the
        right to deny access to or terminate its relationship with any person
        who fails to comply with these laws.
      </Paragraph>
      <Headline level={2}>U.S. Government Rights</Headline>
      <Paragraph>
        All Services and technical data are commercial in nature and developed
        solely at private expense. The Services program and documentation are
        deemed to be "commercial computer software" and "commercial computer
        software documentation", respectively, pursuant to DFAR Section 227.7202
        and FAR Section 12.212(b), as applicable. Any use, modification,
        reproduction, release, performance, display or disclosure of the
        software program and/or documentation by the U.S. Government or any of
        its agencies shall be governed solely by the Terms and shall be
        prohibited except to the extent expressly permitted by the Terms. Any
        technical data provided that is not covered by the above provisions is
        deemed to be "technical data-commercial items" pursuant to DFAR Section
        227.7015(a). Any use, modification, reproduction, release, performance,
        display or disclosure of such technical data shall be governed by the
        terms of DFAR Section 227.7015(b).
      </Paragraph>
      <Headline level={2}>Governing Law</Headline>
      <Paragraph>
        These Terms and any disputes arising out of or related to the use of our
        services shall be governed by and construed in accordance with the laws
        of:
      </Paragraph>
      <ol className={styles.alphabeticalBracketed}>
        <ListItem>
          For users in the European Union (EU): The laws of the Republic of
          Cyprus, excluding its conflict of law principles, shall apply;
        </ListItem>
        <ListItem>
          For users outside the EU: The laws of the State of New York as such
          laws are applied to agreements entered into and to be performed
          entirely within New York between New York residents, and by the laws
          of the United States, excluding its conflict of law principles, shall
          apply. The United Nations Convention on Contracts for the
          International Sale of Goods (1980) is hereby excluded in its entirety
          from application to the Terms. You agree that the Services shall be
          deemed solely based in New York and each a passive Service that does
          not give rise to personal jurisdiction over Tango, either specific or
          general, in jurisdictions other than the State of New York.
        </ListItem>
      </ol>
      <Headline level={2}>Arbitration and Class Action Waiver</Headline>
      <Paragraph>
        PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR
        LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
      </Paragraph>
      <Paragraph>
        You agree that any claim or dispute at law or equity that has arisen or
        may arise between you and Tango relating in any way to or arising out of
        these Terms or your use of or access to the Services will be resolved in
        accordance with the provisions set forth in this Section 22.
      </Paragraph>
      <Paragraph>
        You and Tango will attempt to resolve any dispute through informal
        negotiation within sixty (60) days from the date the notice of dispute
        is sent. That notice should contain proof of your relationship with
        Tango, the nature and legal basis for your or Tango’s dispute, and an
        individual monetary demand if you or we are seeking such relief. During
        this period, the parties shall use their best efforts to settle any
        dispute, claim or disagreement. Any applicable limitations period will
        be tolled during this 60-day informal dispute resolution period. Filing
        any claims before the end of this period and without engaging in this
        process could result in the award of fees in arbitration. If the dispute
        is not resolved within such time period, you agree that any and all
        disputes or claims that have arisen or may arise between you and Tango
        relating in any way to or arising out of these Terms or your use of or
        access to the Services shall be resolved exclusively through final and
        binding arbitration.
      </Paragraph>
      <Paragraph>
        Any dispute, claim or controversy arising out of or relating to any of
        the Terms, including this provision, or the formation, applicability,
        interpretation, breach, termination, validity or enforceability thereof,
        shall be determined by arbitration. The arbitration shall be conducted
        by one arbitrator and administered by the International Chamber of
        Commerce (<b>"ICC"</b>) in accordance with the ICC Arbitration Rules in
        effect at the time of the commencement of the arbitration except as they
        may be modified herein. The seat of the arbitration shall be in New
        York, United States of America, and it shall be conducted in the English
        language. You agree that the existence of the arbitration, any
        information provided in the arbitration, and any submissions, orders or
        awards (<b>"Confidential Information"</b>) made in the arbitration shall
        be kept confidential and you agree not to consent to the publication of
        the Confidential Information.
      </Paragraph>
      <Paragraph>
        Furthermore, you agree that neither you nor Tango will join any claim
        with the claim of any other person or entity in arbitration; that no
        claim will be resolved on a class-wide basis; and that neither you nor
        Tango will assert any claim in a representative capacity on behalf of
        anyone else. Notwithstanding the foregoing, you agree that Tango shall
        be allowed to apply for injunctive remedies (or an equivalent type of
        urgent legal relief) in any jurisdiction. You agree not to commence or
        prosecute any action against any Tango Party other than by filing an
        arbitration complaint in accordance with this paragraph. By agreeing to
        be bound by the Terms, you are waiving any right you have to seek relief
        in a court of law and any right you have to a trial by jury. The parties
        further agree that, if and to the extent this agreement to arbitrate is
        held not apply to any claim, that claim will be tried in a court of
        competent jurisdiction before a judge sitting without a jury.
      </Paragraph>
      <Paragraph>
        <b>30-Day Right to Opt-Out:</b> You reserve the right to opt-out and
        avoid being subject to the arbitration clause by sending written notice
        of your decision to opt-out to the email address specified in section 24
        herein. The notice must be sent within 30 days of your first use of the
        Services, or the issuance of a materially changed clause, whichever is
        later, otherwise you shall be bound to arbitrate disputes in accordance
        with the terms of those paragraphs. If you opt-out of a new clause, the
        prior clause will continue to govern any disputes between you and Tango.
        If you opt-out of arbitration, Tango also will not be bound to
        arbitrate.
      </Paragraph>
      <Headline level={2}>General</Headline>
      <Paragraph>
        <b>Notices:</b> Tango may provide you with notices, including those
        regarding changes to the Terms, by email, via the Services or postings
        on the Tango website: <LinkToTangoWebsite />. Notice will be deemed
        given twenty-four (24) hours after the email is sent. Notice posted on
        the Tango website or via the Services is deemed given ten (10) days
        following the initial posting. Tango reserves the right to determine the
        form and means of providing notifications to our users.
      </Paragraph>
      <Paragraph>
        <b>Amendments:</b> Tango reserves the right to amend any of the Terms at
        any time by publishing the revised Terms, Community Guidelines, Privacy
        Policy, Copyright Policy, Broadcaster Agreement and/or Third Party
        License on the Tango website or by otherwise providing notice of such
        amendment pursuant to the notice provisions above. The revised Terms
        shall become effective following the applicable notice period, unless
        you expressly accept the revised Terms earlier by clicking on the accept
        button. Your express acceptance or continued use of the Services after
        the applicable notice period shall constitute your acceptance to be
        bound by the revised Terms.
      </Paragraph>
      <Paragraph>
        <b>Entire Agreement:</b> The Terms (consisting of the Terms, Community
        Guidelines, Privacy Policy, Copyright Policy, Broadcaster Agreement and
        Third Party License) represent the complete agreement between you and
        Tango and may only be amended as set forth under as expressly provided
        herein. If any provision of the Terms is held to be unenforceable, such
        provision shall be modified only to the extent necessary to make it
        enforceable and shall not affect the enforceability or validity of the
        remaining provisions, which shall remain in full force and effect.
      </Paragraph>
      <Paragraph>
        <b>Survival:</b> Any and all terms and conditions within these Terms
        that should, by their nature, survive termination of your account, will
        survive such termination, including, but not limited to sections 4-10
        and 18-22.
      </Paragraph>
      <Paragraph>
        <b>Assignment:</b> You are not allowed to assign any of the Terms or any
        rights or obligations thereunder. Any attempted transfer or assignment
        in violation thereof shall be null and void. Tango is allowed at its
        sole discretion to assign or transfer any of the Terms and any rights
        thereunder to any third party, without giving of notice.
      </Paragraph>
      <Paragraph>
        <b>Waiver:</b> The failure of any Tango Party to exercise or enforce any
        right or provision of the Terms will not constitute a waiver of such
        right or provision. Any waiver of any provision of the Terms will be
        effective only if in writing and signed by Tango.
      </Paragraph>
      <Paragraph>
        <b>Headings:</b> The heading references herein are for convenience
        purposes only, do not constitute a part of the Terms, and will not be
        deemed to limit or affect any of the provisions hereof.
      </Paragraph>
      <Paragraph>
        <b>Injunctive Relief:</b> You acknowledge that the obligations made
        hereunder to Tango are of a unique and irreplaceable nature, the loss of
        which shall irreparably harm Tango and which cannot be replaced by
        monetary damages alone so that Tango shall be entitled to injunctive or
        other equitable relief (without the obligations of posting any bond or
        surety) in the event of any breach or anticipatory breach by you. You
        irrevocably waive all rights to seek injunctive or other equitable
        relief.
      </Paragraph>
      <Paragraph>
        <b>Third-Party Beneficiaries and Agreements:</b> If you downloaded the
        App from the Apple App Store, you acknowledge and agree that Apple, and
        Apple’s subsidiaries, are third-party beneficiaries of the Terms, and
        that, upon your acceptance of the Terms, Apple will have the right (and
        will be deemed to have accepted the right) to enforce the Terms against
        you as a third-party beneficiary hereof. You agree to comply with, and
        your license to use the Services is conditioned upon your compliance
        with, all applicable third-party terms of the agreement, including those
        of any Application Store, as may be applicable, when using the Services.
      </Paragraph>
      <Paragraph>
        <b>No Partnership, Agency or Joint Venture:</b> The Terms do not create
        or imply any partnership, agency or joint venture.
      </Paragraph>
      <Paragraph>
        <b>Severability:</b> If any of the provisions of the Terms shall be
        deemed invalid, void, or for any reason unenforceable, that provision
        shall be deemed severable and shall not affect the validity or
        enforceability of any remaining provisions.
      </Paragraph>
      <Headline level={2}>Contact Us</Headline>
      <Paragraph>
        If you have any questions about These Terms or the Services, please
        contact us at: <MailToLegal />.
      </Paragraph>
    </article>
  </>
);

export default memo(TermsOfUse);
