import { emitEvent } from "@analytics/emit";

import {
  EventNames,
  EventFields as GeneralEventFields,
} from "src/analytics/enums";
import tangoAnalytics from "src/core/analytics";

import { EventFields, StorageKeys } from "src/core/analytics/enums";
import { GetUserHashedInfoResponse } from "src/features/marketing/api/marketing";
import { loadUserHashedInfo } from "src/features/marketing/state/asyncAction";
import { marketingSelectors } from "src/features/marketing/state/selectors";
import { userSelectors } from "state/selectors";
import { ApplicationThunk } from "state/types";

interface RegistrationParams {
  [GeneralEventFields.COMMENT]: string;
  [GeneralEventFields.LOGIN_PROVIDER]: string;
  [GeneralEventFields.USER_ID]: string;
}

type BIEventCallback = (
  userHashedInfoResponse: GetUserHashedInfoResponse,
  accountId: string
) => void;

export const sendMarketingEventFlow =
  (
    emitMarketingBIEvent: BIEventCallback,
    params?: RegistrationParams
  ): ApplicationThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const analyticsKey = marketingSelectors.getAnalyticsKey(state);
      const userHashedInfo = marketingSelectors.getUserHashedInfo(state);
      const myAccountId = userSelectors.getMyAccountId(state);
      const deduplicationKey = analyticsKey || myAccountId;

      if (!userHashedInfo?.hashedPhoneNumber && !userHashedInfo?.hashedEmail) {
        const userHashedInfoResponse =
          await dispatch(loadUserHashedInfo()).unwrap();

        tangoAnalytics.then((analytics) =>
          analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
            [EventFields.HASHE]: userHashedInfoResponse?.hashedEmail,
            [EventFields.HASHP]: userHashedInfoResponse?.hashedPhoneNumber,
          })
        );

        if (params) {
          emitEvent(EventNames.REGISTRATION_COMPLETED, {
            ...params,
            hashedEmail: userHashedInfoResponse.hashedEmail,
            hashedPhoneNumber: userHashedInfoResponse.hashedPhoneNumber,
          });
        }

        emitMarketingBIEvent(userHashedInfoResponse, deduplicationKey);
      } else {
        emitMarketingBIEvent(userHashedInfo, deduplicationKey);

        tangoAnalytics.then((analytics) =>
          analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
            [EventFields.HASHE]: userHashedInfo?.hashedEmail,
            [EventFields.HASHP]: userHashedInfo?.hashedPhoneNumber,
          })
        );
      }
    } catch (err) {
      if (params) {
        emitEvent(EventNames.REGISTRATION_COMPLETED, params);
      }
    }
  };
