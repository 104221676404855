import { createSlice } from "@reduxjs/toolkit";
import {
  TutorialAction,
  TutorialName,
  TutorialState,
  TutorialsState,
} from "src/features/tutorials/shared/state/types";

const initialState: TutorialsState = {
  [TutorialName.STREAMS_SWIPES]: TutorialState.NOT_COMPLETED,
};

const tutorialsSlice = createSlice({
  name: "tutorials",
  initialState,
  reducers: {
    completeTutorial: (state, { payload }: TutorialAction) => {
      state[payload] = TutorialState.COMPLETED;
    },
    startTutorial: (state, { payload }: TutorialAction) => {
      state[payload] = TutorialState.STARTED;
    },
  },
});

export const tutorialsPersistConfig = {
  transforms: [
    {
      in: (tutorialState: TutorialState) => tutorialState,
      out: (tutorialState: TutorialState, tutorialName: TutorialName) => {
        if (
          tutorialName === TutorialName.STREAMS_SWIPES &&
          tutorialState === TutorialState.STARTED
        ) {
          return TutorialState.COMPLETED;
        }

        return tutorialState;
      },
    },
  ],
};

export const tutorialsActions = tutorialsSlice.actions;
export const tutorialsReducer = tutorialsSlice.reducer;
