import { ErrorSource } from "@datadog/browser-core";
import { LogsEvent } from "@datadog/browser-logs";
import { API_ERROR_NETWORK_ERROR_TEXT } from "src/constants";

const ABORT_ERROR_KIND = "AbortError";

const valueOrEmpty = <T>(value?: T) => value ?? "";

const FILTERS_CRITERIA = [
  function filterNetworkErrors(log: LogsEvent) {
    const [errorOrigin, statusCode] = [
      valueOrEmpty(log?.origin),
      valueOrEmpty(log?.http?.status_code),
    ];

    return statusCode === 0 && errorOrigin === ErrorSource.NETWORK;
  },
  function filterAbortErrors(log: LogsEvent) {
    const errorKind = valueOrEmpty(log?.error?.kind);

    return errorKind.includes(ABORT_ERROR_KIND);
  },
  function filterApiErrorsNetworkErrors(log: LogsEvent) {
    const message = valueOrEmpty(log?.message);

    return message.includes(API_ERROR_NETWORK_ERROR_TEXT);
  },
];

export const filterNetworkErrors = (log: LogsEvent) =>
  !FILTERS_CRITERIA.some((cb) => cb(log));
