import { HTTP_CODE_ERROR, HTTP_CODE_SUCCESS } from "enums/httpCodes";

export enum ReferredUsersResponseCode {
  SERVER_ERROR = HTTP_CODE_ERROR,
  SUCCESS = HTTP_CODE_SUCCESS,
}

export enum ReferredUsersOrder {
  ASC = "asc",
  DESC = "desc",
}
export enum ReferredUsersSortBy {
  EARNED_POINTS = "earnedPoints",
  FIRST_NAME = "firstName",
  POINTS = "points",
  REDEEM_POINTS = "redeemPoints",
}

export enum UserType {
  ALL = "all",
  NEW = "new",
}

export enum ReferralType {
  CREATORS = "creators",
  SUPPORTERS = "supporters",
}
