import { createAsyncThunk } from "@reduxjs/toolkit";
import { loadReferredUsersListRequest } from "src/features/referralProgram/api/referralUsersApi";
import { ReferredUsersListResponse } from "src/features/referralProgram/common/types";
import { isApiError } from "src/features/referralProgram/imports/utils";
import { RootState } from "state/delegate";

export const loadReferralUsersList = createAsyncThunk<
  ReferredUsersListResponse,
  void,
  { rejectValue: string; state: RootState }
>("lwc/referredUsers/loadReferralUsersList", async (payload, api) => {
  try {
    return await loadReferredUsersListRequest(
      api.getState().referralProgram.queryParams
    );
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
