import {
  TutorialName,
  TutorialState,
  TutorialsState,
} from "src/features/tutorials/shared/state/types";

export const localTutorialsSelectors = {
  getStreamsSwipesTutorialCompleted: (state: TutorialsState) =>
    state[TutorialName.STREAMS_SWIPES] === TutorialState.COMPLETED,
  getStreamsSwipesTutorialStarted: (state: TutorialsState) =>
    state[TutorialName.STREAMS_SWIPES] === TutorialState.STARTED,
};
