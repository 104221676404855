import { PayloadAction } from "@reduxjs/toolkit";

export type TutorialAction = PayloadAction<TutorialName>;

export enum TutorialName {
  STREAMS_SWIPES = "streams:swipes",
}

export enum TutorialState {
  COMPLETED = "COMPLETED",
  NOT_COMPLETED = "NOT_COMPLETED",
  STARTED = "STARTED",
}

export type TutorialsState = {
  [key in TutorialName]: TutorialState;
};
